export const bestFittedFor = [
  {
    src: "/images/documentary.png",
    name: "Documentary",
    alt: "documentary",
  },
  {
    src: "/images/interviews.jpg",
    name: "Interviews",
    alt: "interviews",
  },
  {
    src: "/images/debate-and-panels.jpg",
    name: "Debate & Panels",
    alt: "debate-and-panels",
  },
  {
    src: "/images/fiction.jpg",
    name: "Fiction",
    alt: "fiction",
  },
  {
    src: "/images/entertainment.jpg",
    name: "Entertainment",
    alt: "entertainment",
  },
  {
    src: "/images/podcast.jpg",
    name: "Podcast",
    alt: "podcast",
  },
  {
    src: "/images/webinars.jpg",
    name: "Webinars",
    alt: "webinars",
  },
  {
    src: "/images/testimonials.jpg",
    name: "Testimonials",
    alt: "testimonials",
  },
];
