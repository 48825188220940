export function addSearchParamsToUrl(url: string): string {
  if (url.startsWith('https')) {
    const extraParams = getCurrentURLParams();
    url += extraParams;
  }
  return url;
}

export function redirect(url: string, timeout = 0): void {
  setTimeout(() => window.location.assign(addSearchParamsToUrl(url)), timeout);
}

export function redirectInNewPage(url: string): void {
  window.open(addSearchParamsToUrl(url), "_blank");
}

export function getCurrentURLParams(): string {
  return window.location.search;
}
