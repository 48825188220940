import React from "react";
// @mui/material
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
// @components
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { makeStyles } from "@material-ui/styles";

interface PrivacyTermsProps {
  onApiPage: boolean;
}

export default function PrivacyTerms({
  onApiPage,
}: PrivacyTermsProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg">
        <Navbar onApiPage={onApiPage} />
        <Box display="flex" flexDirection="column" mt={5} mb={5}>
          <Typography
            mb={3}
            variant="h2"
            style={{
              color: "#beff34",
              fontFamily: "PolySans-MedianItalic",
              alignSelf: "center",
            }}
          >
            Privacy Policy
          </Typography>

          <Typography
            lineHeight="30px"
            variant="body1"
            style={{ fontFamily: "PolySans-MedianItalic", color: "#beff34" }}
          >
            At QLIP, protecting your personal data is our priority.
          </Typography>
          <Typography lineHeight="30px" variant="body1" color="white">
            When you use the website{" "}
            <a href="https://www.qlip.ai" style={{ color: "#beff34" }}>
              https://www.qlip.ai
            </a>{" "}
            (hereinafter the "<span style={{ color: "#beff34" }}>Website</span>
            ") and/or the web application QLIP (hereinafter the "
            <span style={{ color: "#beff34" }}>Application</span>"), we may
            collect personal data about you.
            <br /> The purpose of this policy is to inform you about how we
            process your personal data in compliance with Regulation (EU)
            2016/679 of 27 April 2016 on the protection of natural persons with
            regard to the processing of personal data and on the free movement
            of such data (hereinafter the "
            <span style={{ color: "#beff34" }}>GDPR</span>").
            <br />
            Please note that when using our services, our API uses{" "}
            <a
              style={{ color: "#beff34" }}
              href="https://developers.google.com/youtube/terms/developer-policies#definition-youtube-api-services"
            >
              YouTube API Services.
            </a>
          </Typography>

          <Typography
            variant="h3"
            mt={3}
            mb={3}
            style={{ fontFamily: "PolySans-MedianItalic", color: "#beff34" }}
          >
            Information We Collect
          </Typography>
          <Typography lineHeight="30px" variant="body1" color="white">
            Personal data is a data that identifies an individual directly or
            indirectly, in particular by reference to an identifier such as a
            name.
            <br />
            We may collect the following personal data:
            <br />
            <span style={{ color: "#beff34" }}>● Identification data</span>{" "}
            (e.g. full name, email);
            <br />{" "}
            <span style={{ color: "#beff34" }}>
              ● Your videos, audio or image files;
            </span>
            <br />{" "}
            <span style={{ color: "#beff34" }}>
              ● Data related to your profession or company
            </span>{" "}
            (e.g. name and logo of your company);
            <br /> <span style={{ color: "#beff34" }}>● Login data</span> (e.g.
            logs and encrypted passwords),
            <br /> <span style={{ color: "#beff34" }}>
              ● Browsing data
            </span>{" "}
            (e.g. IP address, OS, user ID);
            <br />{" "}
            <span style={{ color: "#beff34" }}>
              ● Economic and financial data
            </span>{" "}
            (e.g. bank details, credit cards, details of your purchases and
            subscriptions);‍
            <br /> <span style={{ color: "#beff34" }}>● YouTube API Data:</span>
            <br />
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              data retrieved through the YouTube Analytics API service,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              data provided through the YouTube Reporting API service
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Statistics provided through other YouTube API services, such as
              the number of views for a video, the number of channels for a
              subscriber, or the number of videos in a playlist;
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              If you choose to sign in using a third-party authentication
              service (e.g., Google or Microsoft), certain data such as your
              name and email may be collected from that service. By choosing
              this method, you agree that the service may share this information
              with us. We do not collect your third-party account password;
            </li>
            <br />
            <span style={{ color: "#beff34" }}>
              ● Any information you wish to send us aspart of your contact
              request.
            </span>
            <br />
            <br /> We inform you, when collecting your personal data, whether
            some of these data are mandatory or optional. Mandatory data are
            indicated with an asterisk.
          </Typography>

          <Typography
            variant="h3"
            mt={3}
            mb={3}
            style={{ fontFamily: "PolySans-MedianItalic", color: "#beff34" }}
          >
            Who is the data controller
          </Typography>

          <Typography lineHeight="30px" variant="body1" color="white">
            The data controller is QLIP, Type of Company, registered with the
            Registry of Trade andCompanies of (*) under the number (*) and whose
            head office is located at (*)AI SAS, société par actions simplifiée
            governed by the laws of France, with a share capital of EUR 2,050,
            whose registered office is at 3, Rue Jules Guesde, 91130,
            Ris-Orangis, registered with the trade and commerce registry of
            R.C.S Evry under number 902013838(hereinafter “
            <span style={{ color: "#beff34" }}>Us</span>” or “We”).
          </Typography>

          <Typography
            variant="h3"
            mt={3}
            mb={3}
            color="white"
            style={{ fontFamily: "PolySans-MedianItalic", color: "#beff34" }}
          >
            On what legal basis, for what purposes and for how long do we keep
            your personal data?
          </Typography>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "20px",
                    color: "#beff34",
                    textAlign: "center",
                  }}
                >
                  Purposes
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "20px",
                    color: "#beff34",
                    textAlign: "center",
                  }}
                >
                  Legal basis
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "20px",
                    color: "#beff34",
                    textAlign: "center",
                  }}
                >
                  Data retention period
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  To provide you with our services available on our Website and
                  Application through your account
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  Performance of a contract to which you are party and/or taking
                  steps at your request prior to entering into a contract
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  All your data are retained for the duration of your account.
                  Your connection logs are kept for 1 year.
                  <br />
                  If your account is inactive for 5 years, your personal data
                  will be deleted if you do not reply to our reactivation email.
                  <br />
                  In addition, personal data may be archived for probationary
                  purposes for a period of 5 years.{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  To perform operations related to contracts, invoices and
                  customer relationship management
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  Performance of a contract to which you are party
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  Personal data is retained for the duration of our business
                  relationship.
                  <br />
                  In addition, the data relating to your transactions (with the
                  exception of your banking data) are archived for probationary
                  purposes for a period of 5 years.
                  <br />
                  The data related to your credit card are retained by our
                  payment service provider for the duration of your
                  subscription.
                  <br />
                  The CVV2 (Card Verification Value), listed on your credit card
                  details, will not be stored.
                  <br />
                  Invoices are archived for a period of 10 years.{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  To create a database of customers and prospects
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  Our legitimate interest in developing and promoting our
                  business
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  For our customers: their personal data are retained for the
                  duration of our business relationship.
                  <br />
                  For our prospects: their personal data are retained for a
                  period of 3 years starting from the last contact with us (e.g.
                  communication, action).
                  <br />
                  In addition, personal data may be archived for probationary
                  purposes for a period of 5 years
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  To send newsletters, requests and direct marketing mailings.
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  For our customers: our legitimate interest in winning customer
                  loyalty and informing our customers of our latest news.
                  <br /> For our prospects: your consent (BtoC) or our
                  legitimate interest in winning customer loyalty and informing
                  our customers of our latest news (BtoB).
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  Personal data are retained for a period of 3 years starting
                  from the last contact with us (e.g. communication, action).
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  To improve performance through statistics on YouTube API Data
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    lineHeight: "25px",
                    fontSize: "18px",
                  }}
                >
                  Your consent
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  Your data are retained for 30 days. After this period, your
                  data will be deleted.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  To answer to your information request and other inquiries.
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  Our legitime interest in responding to your inquiries.
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  Personal data are retained during the processing of your
                  request and is deleted once the request has been processed.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  To elaborate analytics of navigation, site audience, and
                  behavior on the Website and the Application.
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  Your consent.
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  Your data are retained for 25 months.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  To process data subjects’ requests to exercise their rights.
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  Our legitimate interest in responding to your requests and
                  keeping records of them.
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    border: "1px solid white",
                    width: "100px",
                    verticalAlign: "baseline",
                    fontSize: "18px",
                    lineHeight: "25px",
                  }}
                >
                  If we ask you a proof of identity: we only retain it for the
                  necessary time to verify your identity. Once the verification
                  has been carried out, the proof is deleted.
                  <br /> If you exercise your right to object to direct
                  marketing: we keep this information for 3 years.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography
            variant="h3"
            mt={3}
            style={{ fontFamily: "PolySans-MedianItalic", color: "#beff34" }}
          >
            Who are the recipients on your personal data?
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "white", lineHeight: "30px" }}
            mt={3}
          >
            Will have access to your personal data:
            <br />
            <span style={{ color: "#beff34", marginLeft: "20px" }}>
              1. The staff of our company;
            </span>
            <br />
            <span style={{ color: "#beff34", marginLeft: "20px" }}>
              2. Our processors: hosting provider, CRM tool, mailing provider,
              payment services provider, analytics provider;
            </span>
            <br />
            <span style={{ color: "#beff34", marginLeft: "20px" }}>
              3. Our partner Google through YouTube API Services. For more
              information on how Google processes your personal data, please
              consult Google Privacy Policy at
              http://www.google.com/policies/privacy
            </span>
            <br />
            <span style={{ color: "#beff34", marginLeft: "20px" }}>
              4. If applicable: public and private organisations, exclusively to
              comply with our legal obligations.
            </span>
          </Typography>

          <Typography
            variant="h3"
            mt={3}
            style={{ fontFamily: "PolySans-MedianItalic", color: "#beff34" }}
          >
            Are you personal data likely to be transferred outside the European
            Union?
          </Typography>

          <Typography
            variant="body1"
            sx={{ color: "white", lineHeight: "30px" }}
            mt={3}
          >
            Your personal data is hosted for the duration of the processing on
            the servers of the company Amazon Web Services located in the
            European Union.
            <br />
            <br />
            As part of the tools, we use (see article on the recipients of your
            personal data, especially our processors), your personal data may be
            transferred outside the European Union. The transfer of your
            personal data in this context is secured with the use of following
            safeguards:
            <br />
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Either personal data are transferred to a country that has been
              recognized as ensuring an adequate level of protection by a
              decision of the European Commission, in accordance with article 45
              of the GDPR : in this case, this country ensures a level of
              protection deemed sufficient and adequate to the provisions of the
              GDPR; or
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              The personal data are transferred to a country whose level of data
              protection has not been recognized as adequate to the GDPR : in
              this case these transfers are based on appropriate safeguards
              indicated in article 46 of the GDPR, adapted to each provider,
              including but not limited to the execution of Standard Contractual
              Clauses approved by the European Commission, the application of
              Binding Corporate Rules or under an approved certification
              mechanism; or
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              The personal data are transferred under the any appropriate
              safeguards described in Chapter V of the GDPR. style=
            </li>
          </Typography>

          <Typography
            variant="h3"
            mt={3}
            style={{ color: "#beff34", fontFamily: "PolySans-MedianItalic" }}
          >
            What rights do you have regarding your personal data?
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "white", lineHeight: "30px" }}
            mt={3}
          >
            You have the following rights with regard to your personal data:
            <br />
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Right to be informed: this is precisely why we have drafted this
              privacy policy as defined by articles 13 and 14 of the GDPR.
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Right to rectification: you have the right to rectify your
              inaccurate, incomplete or obsolete personal data at any time as
              defined by article 16 of the GDPR.
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Right to restriction of processing: you have the right to restrict
              the processing of your personal data in certain cases defined in
              article 18 of the GDPR.
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Right to erasure (“right to be forgotten”): you have the right to
              request that your personal data be deleted and to prohibit any
              future collection as defined by article 17 of the GDPR.
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Right to file a complaint to a competent supervisory authority
              (inFrance, the CNIL), under GDPR article 77, if you consider that
              the processing of your personal data constitutes a breach of
              applicable regulations.
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Right to define instructions related to the retention, deletion
              and communication of your personal data after your death.
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Right to withdraw your consent at any time: for purposes based on
              consent, Article 7of the GDPR provides that you may withdraw your
              consent at any time. Such withdrawal will not affect the
              lawfulness of the processing carried out before the withdrawal.
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Right to data portability: under specific conditions defined in
              article 20 of the GDPR, you have the right to receive the personal
              data you have provided us in a standard machine-readable format
              and to require their transfer to the recipient of your choice.
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Right to object: You have the right to object to the processing of
              your personal data as defined by article 21 of the GDPR. Please
              note that we may continue to process your personal data despite
              this opposition for legitimate reasons or for the defense of legal
              claims.
            </li>
            <br />
            You can exercise these rights by writing us using the contact
            details below. For this matter we may ask you to provide us with
            additional information or documents to prove your identity
          </Typography>

          <Typography
            variant="h3"
            mt={3}
            style={{ color: "#beff34", fontFamily: "PolySans-MedianItalic" }}
          >
            How to exercise your rights?
          </Typography>

          <Typography
            variant="body1"
            sx={{ color: "white", lineHeight: "30px" }}
            mt={3}
          >
            You can exercise your rights by writing at{" "}
            <a href="mailto:hello@qlip.ai" style={{ color: "#beff34" }}>
              hello@qlip.ai
            </a>{" "}
            or at 3 rue Jules Guesde 91130 RIS-ORANGIS, France.
            <br />
            We may ask you for any additional information that may be necessary,
            including, if applicable, a copy of a signed identity document.
            <br />
            In the event of a request for deletion of your data or when you
            revoke your consent, QLIP undertakes to delete your data as soon as
            possible and within 7 calendar days.
            <br />
            For any other request relating to your rights, QLIP undertakes to
            answer you as soon as possible and, in any case, within one month of
            receiving your request. If necessary, this period may be extended by
            two months due to the complexity and number of requests addressed to
            QLIP. In this case, you will be informed of this extension and the
            reasons for the postponement.
            <br />
            If your request is submitted in electronic form, the information
            will also be provided to you electronically where possible, unless
            you expressly request otherwise.
            <br />
            In addition, if you want to delete stored data, you can also revoke
            our access to your personal data via the Google security settings
            page at{" "}
            <a
              href="https://security.google.com/settings/security/permissions"
              style={{ color: "#beff34" }}
            >
              https://security.google.com/settings/security/permissions
            </a>
            .
          </Typography>

          <Typography
            variant="h3"
            mt={3}
            style={{ color: "#beff34", fontFamily: "PolySans-MedianItalic" }}
          >
            What cookies do we use?
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "white", lineHeight: "30px" }}
            mt={3}
          >
            We use “cookies” to collect information about you and your activity
            across our site. A cookie is a small piece of data that our website
            stores on your computer, and accesses each time you visit, so we can
            understand how you use our site. This helps us serve you content
            based on preferences you have specified.
          </Typography>

          <Typography
            variant="h3"
            mt={3}
            style={{ color: "#beff34", fontFamily: "PolySans-MedianItalic" }}
          >
            Limits of Our Policy
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "white", lineHeight: "30px" }}
            mt={3}
          >
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            policies of those sites, and cannot accept responsibility or
            liability for their respective privacy practices.
          </Typography>

          <Typography
            variant="h3"
            mt={3}
            style={{ color: "#beff34", fontFamily: "PolySans-MedianItalic" }}
          >
            Changes to this Policy
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "white", lineHeight: "30px" }}
            mt={3}
          >
            We may modify this privacy policy at any time, in particular in
            order to comply with any regulatory, jurisprudential, editorial or
            technical change. These modifications will apply on the date of
            entry into force of the modified version. Please regularly consult
            the latest version of this privacy policy. You will be kept posted
            of any significant change of the privacy policy.
          </Typography>

          <Typography
            variant="h3"
            mt={3}
            style={{ color: "#beff34", fontFamily: "PolySans-MedianItalic" }}
          >
            Contact Us
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "white", lineHeight: "30px" }}
            mt={3}
          >
            For any questions or concerns regarding your privacy, you may
            contact us using the following details:
            <br />
            email: hello@qlip.ai
            <br />
            address: 3 rue Jules Guesde 91130 RIS-ORANGIS, France
          </Typography>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  tableCell: {
    color: "white",
  },
  typographyStyle: {
    color: "white",
    border: "1px solid white",
    width: "100px",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    border: "1px solid white",
    marginTop: "10px",
  },
}));
