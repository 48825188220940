import React from "react";
// @mui/material
import {
  Box,
  Card,
  Typography,
  Grid,
  useTheme,
  Chip,
  Divider,
  CardContent,
  Button,
  useMediaQuery,
} from "@mui/material";
// @components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
// @data
import { cardsAboutUs, cardsAboutUsLogo } from "../data/aboutUsData";
// @utils
import { redirectInNewPage } from "../utils/navigate";

interface AboutUsProps {
  onApiPage: boolean;
}

export default function AboutUs({ onApiPage }: AboutUsProps): JSX.Element {
  const theme = useTheme();
  const responsiveLaptop = useMediaQuery(theme.breakpoints.only("lg"));
  const responsiveMobileOnly = useMediaQuery(theme.breakpoints.only("xs"));
  const responsiveTablet = useMediaQuery(theme.breakpoints.only("md"));
  const responsiveMobile = useMediaQuery(theme.breakpoints.only("sm"));

  return (
    <Box>
      <Navbar onApiPage={onApiPage} />

      <Grid
        container
        spacing={4}
        alignItems="center"
        mt={10}
        sx={{ overflow: "hidden" }}
      >
        <Grid item xs={12} md={10} sm={12} lg={6} xl={7}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width={
              responsiveLaptop
                ? "70%"
                : responsiveTablet
                  ? "80%"
                  : responsiveMobile || responsiveMobileOnly
                    ? "90%"
                    : "50%"
            }
            mr={responsiveMobileOnly ? 4 : 0}
            ml={
              responsiveMobile
                ? "40px"
                : responsiveMobileOnly
                  ? "32px"
                  : responsiveLaptop
                    ? "170px"
                    : responsiveTablet
                      ? "160px"
                      : "300px"
            }
          >
            <Typography variant="body1" color={theme.palette.primary.main}>
              OUR VISION
            </Typography>

            <Typography variant="h1" color={theme.palette.primary.main} mb={4}>
              Focus on creativity
            </Typography>

            <Typography
              variant="body1"
              color={theme.palette.primary.main}
              lineHeight="25px"
              sx={{ opacity: 0.8, fontFamily: "PolySans-Neutral" }}
            >
              Just 20 years ago, videos were only distributed in movie theatres
              and a few TV channels.
              <br />
              <br />
              Now video is everywhere: social platforms, websites, emails and
              others.
              <br />
              <br />
              But video creators still need to go through tedious manual editing
              to monetise everywhere.
              <br />
              <br />
              We unleash creativity by letting creators focus on what they love:
              their original creation, while our tech empowers them to
              seamlessly spread their creativity everywhere.
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          lg={6}
          xl={5}
          ml={responsiveMobileOnly || responsiveMobile ? "50px" : responsiveTablet ? 20 : "0px"}
          mr={responsiveMobileOnly || responsiveMobile ? "50px" : responsiveTablet ? "160px" : "0px"}
        >
          <Box
            display="grid"
            justifyContent="flex-start"
            alignItems={(responsiveMobileOnly) ? "center" : "inherit"}
            flexDirection={(responsiveMobileOnly || responsiveMobile) ? "column" : "row"}
            mr={responsiveMobileOnly || responsiveMobile || responsiveTablet ? 0 : "-40px"}
            gridTemplateColumns={responsiveMobileOnly ? "1fr" : "1fr 1fr"}
            gap={3}
          >
            <div style={{
              gridRow: 'span 2', position: 'relative',
              borderRadius: '4px',
              overflow: 'hidden',
              minHeight: '300px',
            }}>
              <img
                alt="demo"
                src="/images/demo-image.png"
                style={{
                  // width: responsiveMobileOnly
                  //   ? "75%"
                  //   : responsiveLaptop
                  //   ? "45%"
                  //   : responsiveTablet
                  //   ? "30%"
                  //   : "50%",
                  borderRadius: "32px",
                  border: "1px solid grey",
                  // marginRight: "20px",
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'scale 0.5s',
                }}
              />
            </div>
            <div style={{
              position: 'relative',
              borderRadius: '4px',
              overflow: 'hidden',
              minHeight: '300px',
            }}>
              <img
                alt="about us creativity left"
                src="/images/about_us_creativity_left.jpg"
                style={{
                  // marginTop: responsiveMobileOnly ? "10px" : "0px",
                  // width: responsiveMobileOnly
                  //   ? "75%"
                  //   : responsiveLaptop
                  //   ? "100%"
                  //   : responsiveTablet
                  //   ? "60%"
                  //   : "100%",
                  borderRadius: "32px",
                  border: "1px solid grey",
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'scale 0.5s',
                }}
              />
            </div>
            <div style={{
              position: 'relative',
              borderRadius: '4px',
              overflow: 'hidden',
              minHeight: '300px',
            }}>
              <img
                alt="about us creativity right bottom"
                src="/images/podcast.jpg"
                style={{
                  // marginTop: "20px",
                  borderRadius: "32px",
                  border: "1px solid grey",
                  // width: responsiveMobileOnly
                  //   ? "75%"
                  //   : responsiveLaptop
                  //   ? "100%"
                  //   : responsiveTablet
                  //   ? "60%"
                  //   : "100%",
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  transition: 'scale 0.5s',
                }}
              />
            </div>
          </Box>
        </Grid>
      </Grid>

      <Box
        mt="120px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Chip
          label={<Typography variant="body1">OUR VALUES</Typography>}
          color="primary"
          variant="outlined"
          sx={{
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            marginBottom: 2,
          }}
        />

        <Typography variant="h2" color={theme.palette.primary.main} mb={3}>
          Core values
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        mb="200px"
        mt={5}
        flexWrap="wrap"
        gap={3}
      >
        {cardsAboutUs.map((step) => (
          <Card
            key={step.title}
            sx={{
              borderRadius: "24px",
              // marginRight: responsiveMobileOnly ? "0px" : "20px",
              // marginTop: responsiveMobileOnly ? "20px" : "5px",
              width: "378px",
              height: "312px",
              backgroundImage: `url(${step.background})`,
              backgroundSize: "contained",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <CardContent
              sx={{
                padding: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                color: theme.palette.primary.main,
                fontWeight: 600,
              }}
            >
              {" "}
              <img
                src={step.logo}
                alt={`Logo ${step.title}`}
                style={{
                  alignSelf: "center",
                  width: "120px",
                  height: "120px",
                  marginTop: "50px",
                  marginBottom: "60px",
                }}
              />
              <Divider
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.primary.main,
                }}
              />
              <Typography
                variant="body2"
                px={3}
                mt={2}
                mb={1}
                color="black"
                sx={{
                  textAlign:
                    responsiveMobile || responsiveMobileOnly
                      ? "center"
                      : "left",
                  fontSize: "32px",
                  fontFamily: "PolySans-Neutral",
                }}
              >
                {step.title}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Box
        mt={10}
        mb={10}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        gap={3}
      >
        <Box
          mb={3}
          mr={responsiveMobileOnly ? 0 : 3}
          width="600px"
          height={responsiveMobileOnly ? "390px" : "500px"}
          borderRadius="40px"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          color={theme.palette.primary.main}
          sx={{
            background: `url('/images/qlip-team.jpg')`,
            backgroundSize: responsiveMobileOnly ? "contain" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: responsiveMobileOnly ? "" : "center",
            margin: 0,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              borderBottomLeftRadius: "40px",
              borderBottomRightRadius: "40px",
              backdropFilter: "blur(12px)",
            }}
          >
            <Box display="flex" flexDirection="column" mt={3} mb={3} ml={3}>
              <Typography variant="h6" color="inherit" sx={{ opacity: 0.7 }}>
                PEOPLE
              </Typography>

              <Typography variant="h3" color="inherit">
                Our team
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              style={{
                alignSelf: "center",
                marginRight: "20px",
                width: "164px",
                height: "52px",
                textTransform: "none",
                borderRadius: "40px",
                background: "var(--primary, #BEFF34)",
              }}
              onClick={() =>
                redirectInNewPage(
                  "https://qlipai.notion.site/job-board-423ecebdc9c141ae8e60f53f869b35a4"
                )
              }
            >
              <Typography variant="h6">We're hiring</Typography>
            </Button>
          </Box>
        </Box>

        <Box
          mb={3}
          width="600px"
          height="500px"
          borderRadius="40px"
          display="flex"
          flexDirection="column"
          color={theme.palette.primary.main}
          sx={{
            background: "rgba(255, 255, 255, 0.30)",
            border: "1px solid rgba(255, 255, 255, 0.10)",
            margin: 0,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            flex={1}
            flexWrap="wrap"
            mt={3}
          >
            {cardsAboutUsLogo.map((cardAboutUsLogo) => (
              <div
                key={cardAboutUsLogo.logo}
                style={{
                  border: "1px solid var(--stroke, rgba(255, 255, 255, 0.10))",
                  background: "rgba(255, 255, 255, 0.30)",
                  backgroundImage: `url(${cardAboutUsLogo.logo})`,
                  backgroundSize: responsiveMobileOnly ? "80px" : "110px",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  borderRadius: "50%",
                  width: responsiveMobileOnly ? "110px" : "150px",
                  height: responsiveMobileOnly ? "110px" : "150px",
                  margin: "5px",
                }}
              />
            ))}
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              background: "rgba(255, 255, 255, 0.20)",
              borderBottomLeftRadius: "40px",
              borderBottomRightRadius: "40px",
            }}
          >
            <Box display="flex" flexDirection="column" mt={3} mb={3} ml={3}>
              <Typography variant="h6" color="inherit" sx={{ opacity: 0.7 }}>
                INVESTORS & PARTNERS
              </Typography>

              <Typography variant="h3" color="inherit">
                Backed by
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              style={{
                alignSelf: "center",
                marginRight: "20px",
                width: "164px",
                height: "52px",
                textTransform: "none",
                borderRadius: "40px",
                backgroundColor: "#fff",
              }}
              onClick={() =>
                redirectInNewPage("https://www.linkedin.com/in/svend-cp/")
              }
            >
              <Typography variant="h6">Contact us</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}
