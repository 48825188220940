// @components
import ApiBenefits from "../components/ApiBenefits";
import BackedBy from "../components/BackedBy";
import BestFittedFor from "../components/BestFittedFor";
import Faq from "../components/Faq";
import FeaturesApi from "../components/FeaturesApi";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import StartQlip from "../components/StartQlip";
import ValueForApi from "../components/ValueForApi";

interface ApiProps {
  onApiPage: boolean;
}

export default function Api({ onApiPage }: ApiProps) {
  return (
    <>
      <Navbar onApiPage={onApiPage} />
      <Header onApiPage={onApiPage} />
      <ValueForApi />
      <ApiBenefits />
      <BestFittedFor />
      <FeaturesApi onApiPage={onApiPage} />
      <BackedBy />
      <Faq />
      <StartQlip onApiPage={onApiPage} />
      <Footer />
    </>
  );
}
