import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { valueForApi } from "../data/valueForApi";

export default function ValueForApi(): JSX.Element {
  const theme = useTheme();
  const responsiveMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const responsiveTablet = useMediaQuery(theme.breakpoints.only("sm"));

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        minHeight="600px"
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "black",
          position: "relative",
          overflow: "hidden",
          paddingBlock: '2rem',
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            left: 1500,
            borderRadius: "24px",
            background:
              "radial-gradient(circle at center, #9DD658 0%, rgba(242,233,38,0) 70%)",
            opacity: 0.6,
          },
        }}
      >
        <Chip
          label={<Typography variant="body1">VALUE</Typography>}
          color="primary"
          variant="outlined"
          sx={{
            borderRadius: "40px",
            border: "1px solid rgba(255, 255, 255, 0.16)",
            background:
              "linear-gradient(104deg, rgba(255, 255, 255, 0.20) -6.66%, rgba(255, 255, 255, 0.00) 119.98%)",
            backdropFilter: "blur(10px)",
            marginBottom: "20px",
            marginTop: responsiveMobile || responsiveTablet ? "30px" : "0px",
          }}
        />

        <Typography
          variant="h2"
          color="white"
          align={responsiveMobile ? "center" : "left"}
          sx={{ marginBottom: "20px", fontSize: '3rem', textAlign: 'center'}}
        >
          Save money, grow engagement
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mx="auto"
          width="100%"
          maxWidth="1280px"
        >
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <Grid container display="grid" gridTemplateColumns="repeat(3, minmax(260px, 350px))" gap="1rem" width="100%" justifyContent="center" px="2rem" sx={{
              // gridTemplateColumns: (responsiveMobile || responsiveTablet) ? 'repeat(1, 1fr)' : 'repeat(3, minmax(260px, 350px))',
              gridTemplateColumns: 'repeat(auto-fit, minmax(260px, 350px))',
            }}>
              {valueForApi
                .map((data, index) => (
                  <Grid
                    item
                    // xs={12}
                    // sm={6}
                    // md={4}
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    mt={'1rem'}
                  >
                    <Card
                      key={data.title}
                      sx={{
                        borderRadius: "24px",
                        // marginTop: "5px",
                        // marginBottom:
                        //   responsiveMobile || responsiveTablet ? "30px" : "0px",
                          width: '100%',
                        // width: responsiveMobile ? "250px" : "378px",
                        // height: '100%',
                        minHeight: responsiveMobile ? "250px" : "312px",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundImage: "url(/images/value-for.svg)",
                      }}
                    >
                      <CardContent
                        sx={{
                          padding: 0,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <Typography
                          variant="h1"
                          mb={responsiveMobile ? 6 : 9}
                          align="center"
                          marginY={responsiveMobile ? 5 : 7}
                          flex={'initial'}
                        >
                          {data.title}
                        </Typography>

                        <Divider
                          sx={{
                            width: "100%",
                            opacity: 0.4,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        />
                        <Typography
                          variant="h4"
                          mt={4}
                          color="white"
                          align="center"
                          paddingX={responsiveMobile ? 5 : 0}
                          flex={0.7}
                          px='1rem'
                        >
                          {data.subtitle}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
