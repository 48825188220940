export const cardsAboutUs = [
  {
    title: "Kindness",
    background: "./images/post-clip-card.png",
    logo: "./images/kindness.svg",
  },
  {
    title: "Ambition",
    background: "./images/viral-clip-card.png",
    logo: "./images/ambition.svg",
  },
  {
    title: "Innovative",
    background: "./images/post-clip-card.png",
    logo: "./images/innovative.svg",
  },
];

export const cardsAboutUsLogo = [
  { logo: "/images/360-capital.png" },
  { logo: "/images/aws-logo.png" },
  { logo: "/images/loreal.png" },
  { logo: "/images/les-echos.png" },
  { logo: "/images/brut.png" },
];
