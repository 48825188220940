import React from "react";
// @mui/material
import {
  Typography,
  Box,
  Grid,
  Chip,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
// @data
import { apiSections } from "../data/featureData";
// @utils
import { redirect } from "../utils/navigate";

interface FeaturesApiProps {
  onApiPage: boolean;
}

export default function FeaturesApi({
  onApiPage,
}: FeaturesApiProps): JSX.Element {
  const theme = useTheme();
  const responsiveLaptop = useMediaQuery(theme.breakpoints.only("lg"));
  const responsiveTablet = useMediaQuery(theme.breakpoints.only("md"));
  const responsiveMobile = useMediaQuery(theme.breakpoints.only("sm"));
  const responsiveMobileXS = useMediaQuery(theme.breakpoints.only("xs"));
  const underLaptop = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      id='features-api'
      style={{
        textAlign: "center",
        paddingTop: "50px",
        paddingBottom: "100px",
        overflow: "hidden",
      }}
    >
      <Typography variant="h2" component="h2" sx={{ mt: 10, mb: 10 }} mx="2rem">
        A powerful set of features
      </Typography>

      {apiSections.map((section, index) => (
        <Grid
          key={index}
          container
          style={{
            marginTop: "50px",
            justifyContent: index % 2 === 0 ? "flex-end" : "flex-start",
          }}
        >
          {index % 2 === 0 ? (
            <>
              <Grid
                item
                xl={4}
                md={12}
                sm={12}
                lg={5}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                ml={responsiveLaptop
                  ? "80px"
                  : "40px"}
              >
                <Chip
                  label={<Typography variant="h6">{section.title}</Typography>}
                  sx={{
                    color: theme.palette.primary.main,
                    alignSelf: "flex-start",
                    backgroundColor: "rgba(128, 128, 128, 0.2)",
                    alignItems: "center",
                    border: "1px solid transparent",
                  }}
                />

                <Typography
                  variant="h2"
                  sx={{
                    // maxWidth: responsiveMobileXS ? "80%" : "100%",
                    color: theme.palette.primary.main,
                    marginTop: "10px",
                    alignSelf: "flex-start",
                    textAlign: "start",
                  }}
                >
                  {responsiveMobileXS ? (
                    section.mobileDescription
                  ) : (section.description)}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: "white",
                    marginTop: "10px",
                    alignSelf: "flex-start",
                    textAlign: "start",
                    width: "80%",
                    opacity: 0.6,
                    lineHeight: "25px",
                  }}
                >
                  {section.content}
                </Typography>

                <Button
                  color="inherit"
                  sx={{
                    width: "160px",
                    height: "40px",
                    alignSelf: "flex-start",
                    fontWeight: 700,
                    border: "1px solid rgba(0, 0, 0, 0.16)",
                    background: theme.palette.secondary.main,
                    boxShadow: "0px 8px 12px -4px rgba(0, 0, 0, 0.16)",
                    backdropFilter: "blur(10px)",
                    marginTop: "3rem",
                    borderRadius: "40px",
                    textTransform: "none",
                    "&:hover": {
                      background: theme.palette.secondary.main,
                    },
                  }}
                  onClick={() =>
                    redirect("https://form.typeform.com/to/J6gb4JEU")
                  }
                >
                  <Typography variant="h6">{section.buttonText}</Typography>
                </Button>
              </Grid>

              <Grid item xl={7} md={12} sm={12} lg={6} padding={responsiveTablet ||
                responsiveMobile ||
                responsiveMobileXS ? '40px' : '0px'}>
                {section.image ? (
                  <img
                    src={section.image}
                    alt="feature"
                    style={{
                      display: responsiveTablet ? "flex" : undefined,
                      width: responsiveLaptop || responsiveTablet ||
                        responsiveMobile ||
                        responsiveMobileXS ? "100%" : "80%",
                      borderRadius: "24px",
                      marginRight:
                        responsiveMobile || responsiveMobileXS
                          ? "0px"
                          : responsiveLaptop
                            ? "-40px"
                            : "-260px",
                      marginTop:
                        responsiveTablet ||
                          responsiveMobile ||
                          responsiveMobileXS
                          ? "20px"
                          : "0px",
                    }}
                  />
                ) : (
                  <video
                    src={section.video}
                    autoPlay
                    playsInline
                    loop
                    muted
                    style={{
                      display: responsiveTablet ? "flex" : undefined,
                      width: responsiveLaptop || responsiveTablet ||
                        responsiveMobile ||
                        responsiveMobileXS ? "100%" : "80%",
                      borderRadius: "24px",
                      marginRight:
                        responsiveMobile || responsiveMobileXS
                          ? "0px"
                          : responsiveLaptop
                            ? "-40px"
                            : "-260px",
                      marginTop:
                        responsiveTablet ||
                          responsiveMobile ||
                          responsiveMobileXS
                          ? "20px"
                          : "0px",
                    }}
                  />
                )}
              </Grid>
            </>
          ) : (
            <>
              {underLaptop ? (
                <>
                  <Grid
                    item
                    md={12}
                    xl={5}
                    lg={5}
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      marginLeft:responsiveLaptop
                            ? "80px"
                            : "0px",
                    }}
                    px={responsiveTablet ||
                      responsiveMobile ||
                      responsiveMobileXS ? '40px' : '0px'}
                  >
                    <Chip
                      label={
                        <Typography variant="h6">{section.title}</Typography>
                      }
                      sx={{
                        color: theme.palette.primary.main,
                        alignSelf: "flex-start",
                        backgroundColor: "rgba(128, 128, 128, 0.2)",
                        alignItems: "center",
                        border: "1px solid transparent",
                      }}
                    />

                    <Typography
                      variant="h2"
                      sx={{
                        // maxWidth: responsiveMobileXS ? "100%" : "60%",
                        color: theme.palette.primary.main,
                        marginTop: "10px",
                        alignSelf: "flex-start",
                        textAlign: "start",
                      }}
                    >
                      {section.description}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        marginTop: "10px",
                        alignSelf: "flex-start",
                        textAlign: "start",
                        width: "80%",
                        opacity: 0.6,
                        lineHeight: "25px",
                      }}
                    >
                      {section.content}
                    </Typography>

                    <Button
                      color="inherit"
                      sx={{
                        width: "160px",
                        height: "40px",
                        alignSelf: "flex-start",
                        fontWeight: 700,
                        border: "1px solid rgba(0, 0, 0, 0.16)",
                        background: theme.palette.secondary.main,
                        boxShadow: "0px 8px 12px -4px rgba(0, 0, 0, 0.16)",
                        backdropFilter: "blur(10px)",
                        marginTop: "40px",
                        marginBottom: "10px",
                        borderRadius: "40px",
                        textTransform: "none",
                        "&:hover": {
                          background: theme.palette.secondary.main,
                        },
                      }}
                      onClick={() =>
                        redirect("https://form.typeform.com/to/J6gb4JEU")
                      }
                    >
                      <Typography variant="h6">{section.buttonText}</Typography>
                    </Button>
                  </Grid>

                  <Grid item xl={7} lg={6} md={12} padding={responsiveTablet ||
                    responsiveMobile ||
                    responsiveMobileXS ? '40px' : '0px'}>
                    {section.image ? (
                      <img
                        src={section.image}
                        alt="feature"
                        style={{
                          marginTop: responsiveMobileXS ? "20px" : "0px",
                          display: responsiveTablet ? "flex" : undefined,
                          width: responsiveLaptop || responsiveTablet ||
                            responsiveMobile ||
                            responsiveMobileXS ? "100%" : "80%",
                          borderRadius: "24px",
                          marginLeft:
                            responsiveTablet ||
                              responsiveMobile ||
                              responsiveMobileXS
                              ? "0px"
                              : responsiveLaptop
                                ? "7px"
                                : "-200px",
                          marginBottom:
                            responsiveTablet ||
                              responsiveMobile ||
                              responsiveMobileXS
                              ? "20px"
                              : "0px",
                        }}
                      />
                    ) : (
                      <video
                        src={section.video}
                        autoPlay
                        playsInline
                        loop
                        muted
                        style={{
                          marginTop: responsiveMobileXS ? "20px" : "0px",
                          display: responsiveTablet ? "flex" : undefined,
                          width: responsiveLaptop || responsiveTablet ||
                            responsiveMobile ||
                            responsiveMobileXS ? "100%" : "80%",
                          borderRadius: "24px",
                          marginLeft:
                            responsiveTablet ||
                              responsiveMobile ||
                              responsiveMobileXS
                              ? "0px"
                              : responsiveLaptop
                                ? "7px"
                                : "-200px",
                          marginBottom:
                            responsiveTablet ||
                              responsiveMobile ||
                              responsiveMobileXS
                              ? "20px"
                              : "0px",
                        }}
                      />
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xl={7} lg={6} md={12} padding={responsiveTablet ||
                    responsiveMobile ||
                    responsiveMobileXS ? '40px' : '0px'}>
                    {section.image ? (
                      <img
                        src={section.image}
                        alt="feature"
                        style={{
                          display: responsiveTablet ? "flex" : undefined,
                          width: responsiveLaptop || responsiveTablet ||
                            responsiveMobile ||
                            responsiveMobileXS ? "100%" : "80%",
                          borderRadius: "24px",
                          marginLeft:
                            responsiveTablet ||
                              responsiveMobile ||
                              responsiveMobileXS
                              ? "0px"
                              : responsiveLaptop
                                ? "7px"
                                : "-200px",
                          marginBottom:
                            responsiveTablet ||
                              responsiveMobile ||
                              responsiveMobileXS
                              ? "20px"
                              : "0px",
                        }}
                      />
                    ) : (
                      <video
                        src={section.video}
                        autoPlay
                        playsInline
                        loop
                        muted
                        style={{
                          display: responsiveTablet ? "flex" : undefined,
                          width: responsiveLaptop || responsiveTablet ||
                            responsiveMobile ||
                            responsiveMobileXS ? "100%" : "80%",
                          borderRadius: "24px",
                          marginLeft:
                            responsiveTablet ||
                              responsiveMobile ||
                              responsiveMobileXS
                              ? "0px"
                              : responsiveLaptop
                                ? "7px"
                                : "-200px",
                          marginBottom:
                            responsiveTablet ||
                              responsiveMobile ||
                              responsiveMobileXS
                              ? "20px"
                              : "0px",
                        }}
                      />
                    )}
                  </Grid>

                  <Grid
                    item
                    md={12}
                    xl={5}
                    lg={5}
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      marginLeft:
                        responsiveMobile || responsiveMobileXS
                          ? "40px"
                          : responsiveLaptop
                            ? "80px"
                            : "0px",
                    }}
                  >
                    <Chip
                      label={
                        <Typography variant="h6">{section.title}</Typography>
                      }
                      sx={{
                        color: theme.palette.primary.main,
                        alignSelf: "flex-start",
                        backgroundColor: "rgba(128, 128, 128, 0.2)",
                        alignItems: "center",
                        border: "1px solid transparent",
                      }}
                    />

                    <Typography
                      variant="h2"
                      sx={{
                        // maxWidth: "80%",
                        color: theme.palette.primary.main,
                        marginTop: "10px",
                        alignSelf: "flex-start",
                        textAlign: "start",
                      }}
                    >
                      {section.description}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        marginTop: "10px",
                        alignSelf: "flex-start",
                        textAlign: "start",
                        width: "80%",
                        opacity: 0.6,
                        lineHeight: "25px",
                      }}
                    >
                      {section.content}
                    </Typography>

                    <Button
                      color="inherit"
                      sx={{
                        width: "160px",
                        height: "40px",
                        alignSelf: "flex-start",
                        fontWeight: 700,
                        border: "1px solid rgba(0, 0, 0, 0.16)",
                        background: theme.palette.secondary.main,
                        boxShadow: "0px 8px 12px -4px rgba(0, 0, 0, 0.16)",
                        backdropFilter: "blur(10px)",
                        marginTop: "3rem",
                        borderRadius: "40px",
                        textTransform: "none",
                        "&:hover": {
                          background: theme.palette.secondary.main,
                        },
                      }}
                      onClick={() =>
                        redirect("https://form.typeform.com/to/J6gb4JEU")
                      }
                    >
                      <Typography variant="h6">{section.buttonText}</Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      ))}
    </Box>
  );
}
