import React from "react";
import { BrowserRouter } from "react-router-dom";
// @mui/material
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
// @CSS
import "./App.css";
// @components
import Router from "./Router";

export default function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}
