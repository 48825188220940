import React from "react";
import { useLocation } from "react-router-dom";
// @mui/material
import {
  Avatar,
  Box,
  Button,
  Chip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// @data
import { socialMedia } from "../data/startQlipData";
// @utils
import { redirect } from "../utils/navigate";

interface StartQlipProps {
  onApiPage: boolean;
}

export default function StartQlip({ onApiPage }: StartQlipProps): JSX.Element {
  const theme = useTheme();
  const responsiveMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const pricingLocation = location.pathname === "/pricing";
  const homeLocation = location.pathname === "/";

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight={pricingLocation ? "350px" : "450px"}
      p={5}
      sx={{
        background: `url("./images/bg-start-qlipping.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <img
        src={"./images/qlip-logo.png"}
        alt="Logo"
        style={{ width: "169px" }}
      />

      <Box my={4}>
        <Typography variant="h2" align="center" color="white">
          {onApiPage ? "Pricing" : "Start qlipping for free"}
        </Typography>
      </Box>

      {onApiPage && (
        <Box my={1}>
          <Typography
            variant="body1"
            color="white"
            align={responsiveMobile ? "center" : "left"}
          >
            Book a call to size your volume and use case.
            <br /> We will estimate the best price for your needs.
          </Typography>
        </Box>
      )}

      {homeLocation && (
        <Box my={1}>
          <Typography
            variant="body1"
            color="white"
            align={responsiveMobile ? "center" : "left"}
          >
            14 days & 2 hours worth of content for free!
          </Typography>
        </Box>
      )}

      <Box my={1}>
        <Button
          color="inherit"
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.16)",
            background: theme.palette.secondary.main,
            boxShadow: "0px 8px 12px -4px rgba(0, 0, 0, 0.16)",
            backdropFilter: "blur(10px)",
            width: pricingLocation ? "220px" : "150px",
            height: "40px",
            borderRadius: "40px",
            textTransform: "none",
            marginTop: "20px",
            marginBottom: "20px",
            "&:hover": {
              background: theme.palette.secondary.main,
            },
          }}
          onClick={() =>
            redirect(
              onApiPage
                ? "https://form.typeform.com/to/J6gb4JEU"
                : "https://app.qlip.ai/signup"
            )
          }
        >
          <Typography variant="body2">
            {onApiPage ? "Get a quote" : "Start for free"}
          </Typography>
        </Button>
      </Box>

      {homeLocation && (
        <>
          <Box my={1}>
            <Chip
              label={<Typography variant="body2">OR FOLLOW US</Typography>}
              color="primary"
              variant="outlined"
              sx={{
                color: theme.palette.primary.main,
                alignSelf: "flex-start",
                border: "1px solid rgba(255, 255, 255, 0.16)",
                background:
                  "linear-gradient(143deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                backdropFilter: "blur(10px)",
              }}
            />
          </Box>

          <Box display="flex" justifyContent="center" my={1}>
            {socialMedia.map((social, index) => (
              <Avatar
                key={index}
                sx={{
                  alignSelf: "center",
                  backgroundColor: "black",
                  margin: "10px",
                  padding: "15px",
                  cursor: "pointer",
                }}
                onClick={social.onClick}
              >
                <img src={social.icon} alt={social.alt} />
              </Avatar>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
}
