import { redirectInNewPage } from "../utils/navigate";

export const informations: {
  title: string;
  links: { label: string; link: string; external: boolean }[];
} = {
  title: "Company",
  links: [
    { label: "About us", link: "/about-us", external: false },
    {
      label: "We're hiring",
      link: "https://qlipai.notion.site/job-board-423ecebdc9c141ae8e60f53f869b35a4",
      external: true,
    },
    {
      label: "Contact us",
      link: "https://www.linkedin.com/in/svend-cp/",
      external: true,
    },
  ],
};

export const lastInformations = [
  { title: "Terms of use", onClick: () => redirectInNewPage("/terms-of-use") },
  { title: "Privacy terms", onClick: () => redirectInNewPage("/privacy") },
];
