import React, { useState } from "react";
// @mui/material
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// @utils
import { redirect } from "../utils/navigate";
import { Link } from "react-router-dom";

interface NavbarProps {
  onApiPage: boolean;
}

export default function Navbar({ onApiPage }: NavbarProps): JSX.Element {
  const theme = useTheme();
  const responsiveMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const responsiveMobileSm = useMediaQuery(theme.breakpoints.only("sm"));
  const responsiveLaptop = useMediaQuery(theme.breakpoints.only("lg"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => setDrawerOpen(true);
  const handleCloseDrawer = () => setDrawerOpen(false);

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backdropFilter: "blur(5px)",
          backgroundColor: "transparent",
          mx: "auto",
          width: responsiveMobile
            ? "100%"
            : responsiveLaptop
              ? "80%"
              : responsiveMobileSm
                ? "90%"
                : "72%",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Link to={{
            pathname: "/",
            search: window.location.search
          }}>
            <img
              src={"./images/qlip-logo.png"}
              alt="Logo"
              // onClick={() => redirect("/")}
              style={{ cursor: "pointer", width: "80px", marginRight: "30px" }}
            />
          </Link>

          {!responsiveMobile && (
            <>
              {/* <Button
                color="inherit"
                onClick={() => redirect("/pricing")}
                sx={{ textTransform: "none" }}
              >
                <Typography
                  mr={2}
                  variant="body2"
                  color={theme.palette.primary.main}
                >
                  Pricing
                </Typography>
              </Button> */}

              <Link to={{
                pathname: "/",
                search: window.location.search,
            hash: '#features-api'
              }}>
                <Button
                  color="inherit"
                  // onClick={() => redirect("/about-us")}
                  sx={{ textTransform: "none" }}
                >
                  <Typography variant="body2" color={theme.palette.primary.main}>
                    Features
                  </Typography>
                </Button>
              </Link>
              <Link to={{
                pathname: "/about-us",
                search: window.location.search
              }}>
                <Button
                  color="inherit"
                  // onClick={() => redirect("/about-us")}
                  sx={{ textTransform: "none" }}
                >
                  <Typography variant="body2" color={theme.palette.primary.main}>
                    About Us
                  </Typography>
                </Button>
              </Link>

              <Box flex={1} />

              {!onApiPage && (
                <Button
                  color="inherit"
                  style={{
                    textTransform: "none",
                    border: "1px solid rgba(255, 255, 255, 0.16)",
                    marginRight: "1rem",
                    width: "77px",
                    height: "32px",
                    borderRadius: "40px",
                    background:
                      "linear-gradient(143deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                    boxShadow: "0px 8px 12px -4px rgba(0, 0, 0, 0.16)",
                    backdropFilter: "blur(10px)",
                  }}
                  onClick={() => redirect("https://app.qlip.ai/signin")}
                >
                  <Typography
                    variant="subtitle1"
                    color={theme.palette.primary.main}
                  >
                    Sign in
                  </Typography>
                </Button>
              )}

              <Button
                color="inherit"
                sx={{
                  width: "119px",
                  height: "32px",
                  borderRadius: "50px",
                  textTransform: "none",
                  color: "black",
                  background:
                    "linear-gradient(277.79deg, #86AA39 0.67%, #BEFF34 95.39%), linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16))",
                }}
                onClick={() =>
                  redirect(
                    onApiPage
                      ? "https://form.typeform.com/to/J6gb4JEU"
                      : "https://app.qlip.ai/signup"
                  )
                }
              >
                <Typography variant="subtitle1">
                  {onApiPage ? "Get access" : "Start for free"}
                </Typography>
              </Button>
            </>
          )}

          {responsiveMobile && (
            <>
              <Box flex={1} />

              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleOpenDrawer}
              >
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>
            </>
          )}
        </Toolbar>

        <Box
          style={{
            width: "100%",
            height: "1px",
            background:
              "linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)",
          }}
        />
      </AppBar>

      <Box
        style={{
          backgroundImage: `url("./images/bg-main-dark.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "fixed",
          zIndex: -1,
          inset: 0,
        }}
      />

      <Drawer
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={handleCloseDrawer}
        sx={{ zIndex: 1200 }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          pl={1}
          sx={{
            width: "200px",
            backgroundImage: `url("./images/bg-main-dark.png")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100svh",
          }}
        >
          <img
            width="100px"
            src={"./images/qlip-logo.png"}
            alt="Logo"
            onClick={() => redirect("/")}
            style={{
              cursor: "pointer",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          />

          {/* <Button
            color="inherit"
            onClick={() => redirect("/pricing")}
            sx={{ textTransform: "none" }}
          >
            <Typography variant="h5" color={theme.palette.primary.main} mb={2}>
              Pricing
            </Typography>
          </Button> */}

          <Link to={{
            pathname: "/",
            search: window.location.search,
            hash: '#features-api'
          }}>
            <Button
              color="inherit"
              // onClick={() => redirect("/about-us")}
              sx={{ textTransform: "none" }}
            >
              <Typography variant="h5" color={theme.palette.primary.main}>
                Features
              </Typography>
            </Button>
          </Link>
          <Link to={{
            pathname: "/about-us",
            search: window.location.search
          }}>
            <Button
              color="inherit"
              // onClick={() => redirect("/about-us")}
              sx={{ textTransform: "none" }}
            >
              <Typography variant="h5" color={theme.palette.primary.main}>
                About Us
              </Typography>
            </Button>
          </Link>

          {/* <Button
            color="inherit"
            onClick={() => redirect("/api")}
            sx={{ textTransform: "none" }}
          >
            <Typography
              variant="h5"
              color={theme.palette.primary.main}
              ml={-2}
              mt={2}
            >
              API
            </Typography>
          </Button> */}

          <Box flex={1} />

          {!onApiPage && (
            <Button
              color="inherit"
              style={{
                textTransform: "none",
                border: "1px solid rgba(255, 255, 255, 0.16)",
                width: "120px",
                height: "32px",
                borderRadius: "40px",
                background:
                  "linear-gradient(143deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                boxShadow: "0px 8px 12px -4px rgba(0, 0, 0, 0.16)",
                backdropFilter: "blur(10px)",
              }}
              onClick={() => redirect("https://app.qlip.ai/signin")}
            >
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
              >
                Sign in
              </Typography>
            </Button>
          )}

          <Button
            color="inherit"
            sx={{
              marginTop: "10px",
              marginBottom: "20px",
              width: "120px",
              height: "32px",
              borderRadius: "50px",
              textTransform: "none",
              color: "black",
              background:
                "linear-gradient(277.79deg, #86AA39 0.67%, #BEFF34 95.39%), linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16))",
            }}
            onClick={() =>
              redirect(
                onApiPage
                  ? "https://form.typeform.com/to/J6gb4JEU"
                  : "https://app.qlip.ai/signup"
              )
            }
          >
            <Typography variant="subtitle1">
              {onApiPage ? "Get access" : "Start for free"}
            </Typography>
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
