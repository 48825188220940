import React, { useState } from "react";
import { useLocation } from "react-router-dom";

// @mui/material
import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  IconButton,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// @data
import { questionsPricing } from "../data/pricingData";
import { questionsFaq } from "../data/homeData";

export default function Faq(): JSX.Element {
  const theme = useTheme();
  const location = useLocation();
  const homeLocation = location.pathname === "/";

  return (
    <Box sx={{ backgroundColor: "black", paddingBottom: "50px" }}>
      <Container maxWidth="lg" sx={{ mt: 4, paddingTop: 5 }}>
        <Typography variant="h5" color={theme.palette.primary.main}>
          FAQ
        </Typography>

        {homeLocation ? (
          <Typography
            variant="h2"
            sx={{
              my: 2,
              color: theme.palette.primary.main,
              fontSize: "60px",
              fontStyle: "normal",
              lineHeight: "80px",
            }}
          >
            Most frequently asked{" "}
            <span
              style={{
                fontFamily: "PolySans-MedianItalic",
                color: theme.palette.secondary.main,
              }}
            >
              questions
            </span>
          </Typography>
        ) : (
          <Typography
            variant="h2"
            sx={{
              my: 2,
              color: theme.palette.primary.main,
              fontSize: "60px",
              fontStyle: "normal",
              lineHeight: "80px",
            }}
          >
            All about our{" "}
            <span
              style={{
                fontFamily: "PolySans-MedianItalic",
                color: theme.palette.secondary.main,
              }}
            >
              pricing
            </span>
          </Typography>
        )}

        {homeLocation ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            {questionsFaq.map((question) => (
              <React.Fragment key={question.title}>
                <MenuDropDown
                  title={question.title}
                  content={question.content}
                  theme={theme}
                // pricingLink={question.pricingLink}
                />
              </React.Fragment>
            ))}
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center">
            {questionsPricing.map((question) => (
              <React.Fragment key={question.title}>
                <MenuDropDown
                  title={question.title}
                  content={question.content}
                  theme={theme}
                />
              </React.Fragment>
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
}

interface MenuDropDownProps {
  title?: string;
  content?: string;
  theme: any;
  pricingLink?: string;
}

function MenuDropDown({
  content,
  title,
  theme,
  pricingLink,
}: MenuDropDownProps): JSX.Element {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        minHeight: "80px",
        background: "rgba(255, 255, 255, 0.10)",
        backdropFilter: "blur(40px)",
        boxShadow:
          "0px 8px 24px -8px rgba(0, 0, 0, 0.05), 0px 4px 12px -8px rgba(0, 0, 0, 0.16)",
        my: 2,
        border: "1px solid rgba(255, 255, 255, 0.10)",
        borderRadius: "24px !important",
        overflow: "hidden",
        "&:hover": {
          background: `url(./images/bg-accordion.png)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          transition: "background 0.3s ease-in-out",
        },
        "&.Mui-expanded": {
          background: `url(./images/bg-accordion.png)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          <div
            style={{
              position: "relative",
              borderRadius: "50%",
              width: "48px",
              height: "48px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="40"
              viewBox="0 0 40 41"
              fill="none"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <circle
                cx="20"
                cy="20.0757"
                r="20"
                fill="white"
                fillOpacity="0.16"
              />
            </svg>

            <IconButton
              color="inherit"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "50%",
                padding: "8px",
              }}
            >
              <ArrowDownwardIcon
                fontSize="small"
                sx={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </div>
        }
        aria-controls="panel-content"
        id="panel-header"
        sx={{
          cursor: "pointer",
        }}
      >
        <Typography variant="body1" color={theme.palette.primary.main}>
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Typography
          variant="body2"
          color={theme.palette.primary.main}
          sx={{ opacity: 0.6, lineHeight: "28px" }}
        >
          <div dangerouslySetInnerHTML={{ __html: content ? content : '' }}></div>
        </Typography>

        {/* {pricingLink && (
          <Typography
            variant="body2"
            color={theme.palette.primary.main}
            sx={{ lineHeight: "28px", opacity: 0.6 }}
          >
            Absolutely! We offer a free trial for up to 120 minutes of upload
            that last for 14 days and has watermarks on the clips. After these 2
            weeks, our paying plans are starting at 12$ per month only, for 60
            minutes of upload, watermark free clips. Check our detailed pricing
            here. ({" "}
            <a
              href={pricingLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                color: theme.palette.secondary.main,
              }}
            >
              https://www.qlip.ai/pricing
            </a>{" "}
            )
          </Typography>
        )} */}
      </AccordionDetails>
    </Accordion>
  );
}
