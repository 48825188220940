import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// @components
// @pages
import AboutUs from "./pages/AboutUs";
import PrivacyTerms from "./pages/PrivacyTerms";
import TermsOfUse from "./pages/TermsOfUse";
import Api from "./pages/Api";

export default function Router(): JSX.Element {
  const { pathname, hash, key } = useLocation();
  const onApiPage = window.location.pathname === "/";

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        console.log(element)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/" element={<Api onApiPage={onApiPage} />} />
      {/* <Route path="/pricing" element={<Pricing onApiPage={onApiPage} />} /> */}
      <Route path="/pricing" element={<Api onApiPage={onApiPage} />} />
      <Route path="/about-us" element={<AboutUs onApiPage={onApiPage} />} />
      <Route path="/privacy" element={<PrivacyTerms onApiPage={onApiPage} />} />
      <Route
        path="/terms-of-use"
        element={<TermsOfUse onApiPage={onApiPage} />}
      />
      <Route path="/api" element={<Api onApiPage={true} />} />
    </Routes>
  );
}
