export const backedBy = [
  {
    src: "/images/agoranov.svg",
    name: "Agoranov",
  },
  {
    src: "/images/key-logo.svg",
    name: "Key",
  },
  {
    src: "/images/aws.png",
    name: "AWS",
  },
  {
    src: "/images/loreal.svg",
    name: "L'Oreal",
  },
];
