export const benefits = {
  api: [
    {
      icon: "./images/accuracy_aim.svg",
      title: "Control",
      description: "Your AI-generated clips directly on your platforms.",
      additionalImage: "./images/snow.svg",
    },
    {
      icon: "./images/cost_control.svg",
      title: "Cost management",
      description: "Clear and transparent volume based pricing.",
      additionalImage: "./images/radar.svg",
    },
    {
      icon: "./images/fast_integration.svg",
      title: "Fast integration",
      description: "Our users integrate our API in 2 weeks average.",
      additionalImage: "./images/line-fast.svg",
    },
    {
      icon: "./images/peace_of_mind.svg",
      title: "Peace of mind",
      description: "No infra, no maintenance, no failure, no headache.",
      additionalImage: "./images/line-fast.svg",
    },
    {
      icon: "./images/customizable.svg",
      title: "Customizable",
      description: "Our AI adapts to your content: talk show, documentary,...",
      additionalImage: "./images/radar.svg",
    },
  ],
  web: [
    {
      icon: "./images/pen.svg",
      title: "Manual override",
      description: "Extend, trim, move, reframe,... You're in control.",
      additionalImage: "./images/snow.svg",
    },
    {
      icon: "./images/fast_forward.svg",
      title: "Easy-to-use",
      description: "No edition skill needed - a studio made for marketers.",
      additionalImage: "./images/radar.svg",
    },
    {
      icon: "./images/brand.svg",
      title: "Brand",
      description: "Templates to boost productivity and consistency.",
      additionalImage: "./images/line-fast.svg",
    },
    {
      icon: "./images/user-group.svg",
      title: "Team workspace",
      description: "Collaborate on projects and edits seamlessly.",
      additionalImage: "./images/line-fast.svg",
    },
    {
      icon: "./images/share.svg",
      title: "Direct share",
      description: "Post in one-click to Youtube and TikTok - more to come...",
      additionalImage: "./images/radar.svg",
    },
  ]
};
