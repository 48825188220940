import React from "react";
// @mui/material
import {
  Box,
  Container,
  Grid,
  Typography,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
// @data
import { informations, lastInformations } from "../data/footerData";
// @utils
import { addSearchParamsToUrl, redirectInNewPage } from "../utils/navigate";
import { Link, LinkProps } from "react-router-dom";

export default function Footer(): JSX.Element {
  const theme = useTheme();
  const responsiveDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  const responsiveLaptop = useMediaQuery(theme.breakpoints.only("lg"));
  const responsiveMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box bgcolor="black" p={8}>
      <Container maxWidth="xl">
        <Grid
          container
          display="flex"
          justifyContent="flex-end"
          spacing={responsiveDesktop ? 18 : responsiveLaptop ? 14 : 0}
        >
          <Grid
            item
            width="300px"
            key={informations.title}
            textAlign={"right"}
          >
            <Typography
              variant={responsiveMobile ? "body1" : "h4"}
              sx={{
                color: theme.palette.primary.main,
                marginBottom: "15px",
              }}
            >
              {informations.title}
            </Typography>

            {informations.links.map((informationLink) => {
              const to: LinkProps['to'] = informationLink.external ? addSearchParamsToUrl(informationLink.link) : {
                pathname: informationLink.link,
                search: window.location.search
              }
              return (
                <Link to={to} key={informationLink.label} target={informationLink.external ? "__blank" : "_self"} style={{ textDecoration: 'none' }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.primary.main,
                      marginBottom: "10px",
                      cursor: "pointer",
                      textDecoration: 'none',
                      "&:hover": { textDecoration: "underline" },
                    }}
                  >
                    {informationLink.label}
                  </Typography>
                </Link>
              )
            })}

            {/* {information.case && (
              <Link to={information.case.link}>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.primary.main,
                    marginBottom: "10px",
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  {information.case.label}
                </Typography>
              </Link> */}
            {/* <Typography
                variant="body2"
                sx={{
                  color: theme.palette.primary.main,
                  marginBottom: "10px",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={information.case.onClick}
              >
                {information.case.label}
              </Typography>
            )} */}

            {/* {information.case2 && (
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.primary.main,
                  marginBottom: "10px",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={information.case2.onClick}
              >
                {information.case2.label}
              </Typography>
            )}

            {information.case3 && (
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.primary.main,
                  marginBottom: "10px",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={information.case3.onClick}
              >
                {information.case3.label}
              </Typography>
            )}

            {information.case4 && (
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.primary.main,
                  marginBottom: "10px",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={information.case4.onClick}
              >
                {information.case4.label}
              </Typography>
            )} */}
          </Grid>
        </Grid>

        <Box display="flex" alignItems="center" pt={5}>
          <Typography
            variant={responsiveMobile ? "body2" : "h4"}
            sx={{
              color: theme.palette.primary.main,
              flex: 1,
            }}
          >
            Follow us
          </Typography>

          <IconButton
            onClick={() => redirectInNewPage("https://twitter.com/QlipAI")}
          >
            <img src={"/images/twitter.svg"} alt="twitter" />
          </IconButton>

          <IconButton
            onClick={() =>
              redirectInNewPage("https://www.linkedin.com/company/qlipai/")
            }
          >
            <img src={"/images/linkedin.svg"} alt="linkedin" />
          </IconButton>
        </Box>

        <Divider
          sx={{
            backgroundColor: theme.palette.primary.main,
            marginTop: "10px",
          }}
        />

        <Box display="flex" justifyContent="space-between" pb={5}>
          {lastInformations.map((lastInformation) => (
            <Typography
              key={lastInformation.title}
              variant="subtitle1"
              color={theme.palette.primary.main}
              mr={5}
              mt={3}
              onClick={lastInformation.onClick}
              sx={{
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              {lastInformation.title}
            </Typography>
          ))}

          <Box sx={{ flex: 1 }} />

          <Typography
            variant="subtitle1"
            color={theme.palette.primary.main}
            mt={3}
          >
            Qlip {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
