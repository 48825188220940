// @mui/material
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// @data
import { bestFittedFor } from "../data/bestFittedFor";

export default function BestFittedFor(): JSX.Element {
  const theme = useTheme();
  const responsiveMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Typography
        variant="h2"
        component="h2"
        sx={responsiveMobile ? { mt: 10, mb: 3, fontSize: '50px', lineHeight: '70px' } : { my: 10 }}
        align="center"
        px="2rem"
      >
        Works with conversational video/audio content
      </Typography>

      <Grid container spacing={1} sx={{ mt: responsiveMobile ? 0 : 10 }}>
        {bestFittedFor.map((bestFit) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            key={bestFit.name}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card
              key={bestFit.name}
              sx={{
                width: responsiveMobile ? "170px" : "280px",
                border: "1px solid rgba(255, 255, 255, 0.10)",
                background: "rgba(255, 255, 255, 0.10)",
                height: responsiveMobile ? "170px" : "300px",
                boxShadow:
                  "0px 8px 24px -8px rgba(0, 0, 0, 0.05), 0px 4px 12px -8px rgba(0, 0, 0, 0.16)",
                backdropFilter: "blur(40px)",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                margin: responsiveMobile ? "10px 10px" : "30px 10px",
                borderRadius: responsiveMobile ? "0px" : "24px",
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img
                  src={bestFit.src}
                  alt={bestFit.alt}
                  style={responsiveMobile ? {
                    height: '186px',
                    objectFit: 'cover',
                    width: 'calc(100% + 32px)',
                    borderRadius: 0,
                  } : {
                    width: '100%',
                    maxWidth: '222px',
                    height: '186px',
                    objectFit: 'cover',
                    borderRadius: '0.7rem',
                  }}
                />

                <Typography
                  variant="h5"
                  component="h2"
                  mt={responsiveMobile ? 1 : 2}
                  mb={responsiveMobile ? 2 : 0}
                >
                  {bestFit.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
