import { redirectInNewPage } from "../utils/navigate";

export const questionsPricing = [
  {
    title: "Can I try it for free?",
    content:
      "Absolutely! We offer a free trial for up to 60 minutes of upload and watermarks on the clips.",
  },
  {
    title: "Do I need to enter a credit card to try qlip?",
    content:
      "No, you can start qlipping as soon as you create an account, and it does not required a credit card. Once you have tested and want to remove the watermark, or reach the end of your free trial, you can decide to upgrade to a paying plan to get more minutes, no watermark, and premium features.",
  },
  {
    title: "When will I get charged?",
    content:
      "The payment is processed straight away when you purchase a paying plan. Then, you will get charged once per month at the renewal date. To be very concrete, if you subscribed on the 9th of July, your next payment will happen on the 9th of August.",
  },
  {
    title: "How does the minutes count work?",
    content:
      "Minutes works like credits. When you upload a video, and it generates highlights (so in case there would be a fail, it would not use your minutes) the minutes will get taken out of your credit. To be very practical, if you purchase a Starter plan today, you will get 60 minutes of credit. Then let’s say you upload a 15 minutes long video, once you highlights have been generated you will have 45 minutes of upload left.",
  },
  {
    title: "Can I keep my minutes from one month to another?",
    content:
      "Your minutes will expire at the end of the billing cycle. It means that the unused minutes from this month will not roll over to next month.",
  },
  {
    title: "Can I cancel my subscription?",
    content:
      "Yes you can cancel your subscription anytime, this is a commitment free subscription. That being said, when ou decide to cancel, you will lose the minutes associated to your subscription, so we advise to cancel close to a renewal date if you really want to do so.",
  },
];

export const cardsPricing = [
  {
    plan: "STARTER",
    sliderValue: 20,
    upload: "Upload up to 60mins",
    price: "12$/mo",
    storage: "50Gb of storage",
    watermarks: "Unlimited clips without watermarks",
    user: "1 user",
    support: "Email support",
    button: "Start free trial",
    onClick: () => redirectInNewPage("https://app.qlip.ai/signup"),
  },
  {
    chip: "RECOMMENDED",
    sliderValue: 40,
    plan: "PRO",
    upload: "Upload up to 200mins",
    price: "30$/mo",
    storage: "100Gb of storage",
    watermarks: "Unlimited clips without watermarks",
    user: "1 user",
    support: "Live call support",
    button: "Start free trial",
    onClick: () => redirectInNewPage("https://app.qlip.ai/signup"),
  },
  {
    plan: "EXPERT",
    sliderValue: 60,
    upload: "Upload up to 500mins",
    price: "50$/mo",
    storage: "250Gb of storage",
    watermarks: "Unlimited clips without watermarks",
    user: "2 users",
    support: "Priority support",
    button: "Start free trial",
    onClick: () => redirectInNewPage("https://app.qlip.ai/signup"),
  },
  {
    plan: "CUSTOM",
    sliderValue: 80,
    upload: "Upload up to 500mins",
    price: "50$/mo",
    storage: "250Gb of storage",
    watermarks: "Unlimited clips without watermarks",
    user: "2 users",
    support: "Priority support",
    button: "Get this plan",
    onClick: () => redirectInNewPage("https://app.qlip.ai/signup"),
  },
];
