import { createTheme } from "@mui/material/styles"

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#BEFF34", // pop green
    },
    success: {
      main: "#1FA273",
    },
    error: {
      main: "#E44C37",
    },
    warning: {
      main: "#FFBE2E",
    },
  },
  typography: {
    fontFamily: "PolySans-Median",
    h1: {
      fontFamily: "PolySans-Median",
      fontSize: "72px",
      fontStyle: "normal",
      lineHeight: "80px",
      color: "white"
    },
    h2: {
      fontFamily: "PolySans-Median",
      fontSize: "60px",
      fontStyle: "normal",
      lineHeight: "80px",
      color: "white"
    },
    h3: {
      fontFamily: "PolySans-Median",
      fontSize: "48px",
      fontStyle: "normal",
      lineHeight: "normal",
      color: "white"
    },
    h4: {
      fontFamily: "PolySans-Median",
      fontSize: "28px",
      fontStyle: "normal",
      lineHeight: "normal",
      color: "white"
    },
    h5: {
      fontFamily: "PolySans-Median",
      fontSize: "20px",
      fontStyle: "normal",
      lineHeight: "normal",
      color: "white",
    },
    h6: {
      fontFamily: "PolySans-Median",
      fontSize: "18px",
      fontStyle: "normal",
      lineHeight: "normal",
    },
    body1: {
      fontFamily: "PolySans-Median",
      fontSize: "20px",
      lineHeight: "normal",
    },
    body2: {
      fontFamily: "PolySans-Median",
      fontSize: "16px",
      fontStyle: "normal",
      lineHeight: "normal",
    },
    subtitle1: {
      fontFamily: "PolySans-Median",
      fontSize: "14px",
      lineHeight: "20px",
    },
    subtitle2: {
      fontFamily: "PolySans-Median",
      fontSize: "10px",
      lineHeight: "14px",
    },
  },
  components: {},
})

export default customTheme
