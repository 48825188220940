import React from "react";
// @mui/material
import { Box, Container, Typography } from "@mui/material";
// @components
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

interface TermsOfUseProps {
  onApiPage: boolean;
}

export default function TermsOfUse({ onApiPage }: TermsOfUseProps) {
  return (
    <>
      <Container maxWidth="lg">
        <Navbar onApiPage={onApiPage} />
        <Box display="flex" flexDirection="column">
          <Typography
            align="center"
            variant="h2"
            mb={5}
            mt={5}
            fontFamily="PolySans-MedianItalic"
            color="#BEFF34"
          >
            Terms of Service
          </Typography>
          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            1. Object
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            QLIP AI ("<span style={{ color: "#beff34" }}>QLIP AI</span>") offers
            its clients a turnkey service enabling them to extract the
            highlights of their long recordings and to produce clips from these
            extracts, particularly for internal or external communication
            purposes.
            <br /> <br />
            The services are accessible through the QLIP AI platform available
            at{" "}
            <a
              href="https://www.qlip.ai"
              style={{ textDecoration: "underline", color: "#BEFF34" }}
            >
              https://www.qlip.ai
            </a>{" "}
            (the "<span style={{ color: "#beff34" }}>Platform</span>"). The
            services offered by QLIP AI on the Platform (the "
            <span style={{ color: "#beff34" }}>Services</span>") are accessible
            via:
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              standard offers as described on the Platform (“Standard offers”),
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              a customized offer made on the basis of a quote (the “Customized
              offer”)
            </li>{" "}
            <br />
            The purpose of these general terms and conditions and, where
            applicable, the quote(s) attached thereto (the "Quote") is to define
            the terms and conditions for the provision of the Services to the
            client as identified in said Quote (the "Client") and to define the
            rights and obligations of the parties in this context.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            2. Identification of the company
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            2. Identification of the company The Platform and the Services are
            operated by the company QLIP AI, a société par actions simplifiée
            (“simpliﬁed joint stock company”), registered in the Commerce du
            Registre et des Sociétés of Evry under number 902013838, which
            registered office is located at 3 rue Guesde, 91 130, Ris-Orangis,
            France.
            <br /> <br />
            QLIP AI can be contacted at the following address:
            <br />
            E-mail address: hello@qlip.ai,
            <br />
            Mail: 3 rue Guesde, 91 130, Ris-Orangis, France,
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            3. Contractual documents
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            These general terms and conditions (the{" "}
            <span style={{ color: "#BEFF34" }}>“Terms and Conditions”</span>
            ),and, where applicable, the Quote, constitute an indivisible
            contractual whole(the "
            <span style={{ color: "#beff34" }}>Contract</span>"), to the
            exclusion of any other special terms and conditions or general terms
            and conditions of the Client that have not been expressly approved
            by QLIP AI. In the event of contradiction, the provisions of the
            Quote shall prevail over those of the Terms and Conditions.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            4. Legal capacity
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The Client can be:
            <br />
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              a natural person with full legal capacity or failing that be at
              least 13 years old and have obtained the agreement of their legal
              representative, and the Client agree to provide us with their
              email address so that QLIP AI can contact them.
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              or a legal person acting through a natural person who has the
              power or authority to enter into a contract in its name and on its
              behalf.
            </li>
            <br />
            The Client can have the status of:
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              consumer, understood as any natural person acting for purposes
              which are outside his or her trade or profession,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              or professional, understood as any natural or legal person acting
              for purposes relating to its commercial, industrial, handmade,
              liberal or agricultural activity, including when acting in the
              name of or on behalf of another professional.
            </li>
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            5. Who are the recipients on your personal data?
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            Will have access to your personal data: <br />
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              The staff of our company;
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              processors: hosting provider, CRM tool, mailing provider, payment
              services provider, analytics provider;
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Our If applicable: public and private organisations, exclusively
              to comply with our legal obligations.
            </li>
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            6. Terms of access to the Services
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            In order to use the Services, the Client must have the following
            hardware or software: a computer or mobile with internet connection
            and a browser.
            <br /> The Client can access the Services by going directly to the
            Platform
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            6.1. Order of the Services and acceptance of the Contract
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The Client subscribes to the Services on a subscription basis (the
            "Subscription").
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            6.2. In case of Stands offer's Subscription via the form on the
            Platform
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            To subscribe to the Services, the Client fills in the form available
            on the Platform. TheClient must provide all information marked as
            mandatory. Any incomplete registration will not be validated.
            <br />
            <br />
            Acceptance of these Terms and Conditions is materialized by a
            checkbox
            <br />
            <br />
            Registration automatically entails the opening of an account in the
            Client’s name (the"<span style={{ color: "#beff34" }}>Account</span>
            ") which allows them to access to the Services by using their login
            and password.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            6.3. In case of Customized offers Subscription
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            To subscribe to the Services, the Client shall contact QLIP AI using
            the contact details indicated at the article “Identification of the
            company “. It is Client's responsibility to provide QLIP AI with the
            relevant information and documents to enable it to identify its
            needs and expectations. QLIP AI will then analyze the Client's needs
            and will draw up one or more Quotes on this basis. Unless otherwise
            stated, any Quote issued by QLIPAI is valid for 30 days from its
            issuance. If the Client fails to validate it within this period, it
            shall lapse.
            <br />
            <br />
            The Client which wishes to order the Services must validate the
            corresponding Quote within the above period, by any useful written
            means and in particular by email.
            <br />
            <br /> Acceptance of these Terms and Conditions is evidenced by the
            validation of a Quote
            <br />
            <br />
            Any validation of a Quote, whether express or implied, implies full
            acceptance of these Terms and Conditions, in their version in force
            at the date of the Quote concerned. Any acceptance subject to a
            reservation shall be considered null and void.
            <br />
            <br /> QLIP shall open an account on behalf of the Client (also the
            "<span style={{ color: "#beff34" }}>Account</span>").
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            7. Creation of User Accounts
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            Once theAccount has been created, the Client may freely create
            accesses for users("<span style={{ color: "#beff34" }}>Users</span>
            ". The number ofUsers may be limited as specified in the subscribed
            Standard offer or in the signed Quote.
            <br />
            <br />
            The Client is solely responsible for creating accesses for Users,
            for setting their access rights and for their personal use of the
            Platform.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            8. Description of the Services
          </Typography>
          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            8.1 The Services
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The Client acknowledges:
            <br />
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              that they are aware of the characteristics and constraints,
              particularly technical, of the Services,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              that the implementation of theServices requires a connection to
              the Internet and that the quality of theServices depends on this
              connection, for which QLIP AI is not responsible.
            </li>
            <br />
            Depending on the offer chosen, the Client will have access to the
            services described in theQuote or all or part of the Services
            described on the Platform. In particular, the Client will be able to
            upload their videos in order to obtain the highlights of the latter.
            To do this, the Client must: <br />
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Upload a video to the Platform(the “Video”),
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Choose what social platform they want to target with the uploaded
              video
            </li>
            <br />
            Once the above elements have been processed by the Platform, the
            Client will be able to:
            <br />
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              watch the highlight clips,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              trim the Video using text-based editing,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              resize the Videos with the various options offered on the
              Platform.
            </li>
            <br />
            The finalizedVideo (the "
            <span style={{ color: "#beff34" }}>Final Video</span>") will be
            available for download by the Client.
            <br />
            <br />
            By default, and unless the Client expressly refuses by any useful
            written means, the Client acknowledges and accepts that QLIP AI may
            access the Video, in particular for the following purposes:
            <br />
            resize the Videos with the various options offered on the Platform.
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              providing personalized advice to the Client,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              assist the Client when the latter requests it on the Platform,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              enrich the Client's algorithms,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              provide technical support.
            </li>
            <br />
            Moreover, the Client expressly authorizes QLIP AI, for the legal
            duration of the author’s rights and for the whole world, to use the
            Video and the Final Video to promote its Services and enrich its
            algorithm.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            8.2 Youtube API
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The Client acknowledges and expressly agrees that the Services are
            performed via the Youtube API.
            <br /> Therefore, the use of the Services implies that the Client
            agrees to be bound by the general terms of use of the Youtube API
            available at the following link:{" "}
            <a
              href="https://www.youtube.com/t/terms"
              style={{ color: "#beff34" }}
            >
              https://www.youtube.com/t/terms.
            </a>
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            9 Additional Services
          </Typography>
          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            9.1 Maintenance
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            During the duration of the Services, the Client benefits from
            maintenance, in particular corrective and evolutionary maintenance.
            In this context, access to thePlatform may be limited or suspended.
            <br />
            <br />
            Concerning corrective maintenance, QLIP AI shall make its best
            efforts to provide theClient with corrective maintenance in order to
            correct any malfunction or bug found on the Platform.
            <br />
            <br />
            Concerning the evolutionary maintenance, the Client benefits during
            the duration of theServices from an evolutionary maintenance, which
            QLIP AI may carry out automatically and without prior notice, and
            which includes improvements of the functionalities of the Platform
            and/or technical facilities used within the framework of the
            Platform (aiming at introducing minor or major extensions).
            <br />
            <br />
            Access to the Platform may also be limited or suspended for planned
            maintenance purposes, which may include the above-mentioned
            corrective and evolutionary maintenance operations
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            9.2 Hosting
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            QLIP AI provides, under the terms of an obligation of means, the
            hosting of the Platform, as well as the data entered on the
            Platform, on its servers or through the intermediary of a
            professional hosting provider, and on servers located in a territory
            of the European Union.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            10. Modification of the Services
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            If the Client wishes to modify the content of its Subscription (and
            in particular the number of User Accounts or the possible duration
            of the Videos to upload as defined below), they must:
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              subscribe to another Standard offer or,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              request a Quote from QLIP AI. QLIP AI will draw up a new Quote
              which must be validated by the Client and will replace the
              previous Quote.
            </li>
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            11. Duration of the Services, termination
          </Typography>
          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            11.1 Concerning the Standard offers
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The Subscription to a Standard offer is taken out for the period
            indicated on thePlatform.
            <br />
            The Subscription is then automatically renewed for successive
            periods of the same duration as the initial Subscription period
            mentioned above (together with the initial period the "Subscription
            Periods"),from date to date, unless terminated by QLIP AI or by the
            Client until the last day of the current Subscription Period, under
            the conditions described below.
            <br />
            <br />
            Cancellation of the Subscription may be made by each party by email
            or directly from the Platform.
            <br />
            <br />
            Termination of the Subscription shall be effective at the end of the
            last Subscription Period in progress. Any Subscription Period
            started shall be due in full.
            <br />
            <br />
            <span style={{ color: "#beff34" }}>
              If the Client is a consumer, according to article L215-1 of the
              French Consumer Code:
            </span>
            <br />
            <br />
            <span style={{ color: "#beff34" }}>
              For service contracts concluded for a fixed term with a tacit
              renewal clause, the professional providing the service shall
              inform the consumer in writing, by a dedicated letter or e-mail,
              no earlier than three months and no later than one month before
              the end of the period authorizing the rejection of renewal, of the
              possibility of not renewing the contract he has concluded with a
              tacit renewal clause. This information shall be given in clear and
              comprehensible terms and shall mention, in a visible box, the
              deadline for non-renewal.
              <br />
              <br />
              Where this information has not been sent to him in accordance with
              the provisions of the first paragraph, the consumer may terminate
              the contract free of charge at any time from the renewal date.
              <br />
              <br />
              Advances made after the last renewal date or, in the case of
              open-ended contracts, after the date of conversion of the initial
              fixed-term contract, shall in this case be reimbursed within
              thirty days of the date of termination, after deduction of the
              sums corresponding, up to that date, to the performance of the
              contract. The provisions of this article shall apply without
              prejudice to those which by law subject certain contracts to
              special rules as regards consumer information.
            </span>
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            11.2. Concerning Customized offers
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The Subscription of a Customized offer is taken out for an initial
            period specified in the Quote,
            <br />
            <br />
            which starts from the date of the beginning of the subscription
            indicated in the Quote. Unless otherwise provided in the Quote, the
            Subscription is then tacitly renewed for periods of the same
            duration if the initial period, unless terminated by QLIP or by the
            Client until the last day of the current Subscription Periods.
            <br />
            <br />
            Termination of the Subscription by either party shall be effected by
            sending a request to that effect to the other party by registered
            letter with acknowledgement of receipt to the address mentioned at
            the article “Identification of the company”, mentioning the will to
            terminate the Subscription.
            <br />
            <br /> Termination of the Subscription shall be effective at the end
            of the last Subscription Period in progress. Any Subscription Period
            started shall be due in full.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            12. Financial conditions
          </Typography>
          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            12.1. Prices and payment terms
          </Typography>
          <Typography variant="h4" style={{ color: "#beff34" }} mb={3}>
            12.1.a. In case of subscription to a Standard offer
          </Typography>
          <Typography variant="body1" style={{ color: "#beff34" }} mb={1}>
            a) Subscription price
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The price is indicated on the Platform and is expressed in euros and
            tax excluded.
          </Typography>
          <Typography
            variant="body1"
            mb={1}
            mt={1}
            style={{ color: "#beff34" }}
          >
            b) Invoicing and payment terms
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
            mb={3}
          >
            QLIP AI sends the Client an invoice per Subscription Periods by any
            useful means. Payment is made by direct debit on a monthly basis as
            from the Subscription date.
          </Typography>
          <Typography variant="h4" style={{ color: "#beff34" }} mb={3}>
            12.1.b. In case of subscription to a Customized offer
          </Typography>
          <Typography variant="body1" mb={1} style={{ color: "#beff34" }}>
            a) Subscription price
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The price of the Subscription is indicated on the Quote.
          </Typography>
          <Typography
            variant="body1"
            mb={1}
            style={{ color: "#beff34" }}
            mt={1}
          >
            b) Invoicing and payment terms
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The invoicing terms are specified in the Quote. Unless otherwise
            agreed by the parties, invoices shall be sent to the Client on a
            monthly basis by any means and shall be paid by bank transfer within
            30 days from their issuance.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            12.2. Overage Price
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            Each offer includes a number of hours of download time.
            <br />
            When the Client exceeds the number of hours of downloading included
            in the offer to which they have subscribed on the Platform or by
            Quote, they must:
            <br />- If they have subscribed on the Platform: subscribe to a
            Subscription above or request a Quote in accordance with the
            conditions of the article &quot;Order of the Services and acceptance
            of the Contract&quot;.
            <br /> - If they have subscribed with a Quote, the invoicing
            conditions are indicated on the latter.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            12.3. Payment delays and incidents
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            In the event of default or delay in payment, QLIP AI reserves the
            right, from the day after the due date specified on the invoice:
          </Typography>

          <Typography variant="h4" style={{ color: "#beff34" }} mt={3} mb={3}>
            (i) for all Clients:
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
            mb={3}
          >
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              To declare all sums owed by the Client to QLI AI to be in arrears
              and immediately payable,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              Immediately suspend the access to the Platform until full payment
              of the amounts due,
            </li>
          </Typography>

          <Typography variant="h4" style={{ color: "#beff34" }} mb={3}>
            (ii) for Professional Clients:
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            Invoice for it benefit an interest on arrears equal to 3 times the
            legal interest rate, based on the amount of the sums not paid on the
            due date and a fixed indemnity of 40 euros for recovery, without
            prejudice to additional compensation if the recovery actually
            incurred exceed this amount.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            13. Right of withdrawal
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            if:
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              the Client is a consumer,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              the Client is a non-professional fulfilling the conditions set out
              in the French Consumer Code,
            </li>
            <br />
            ... the Client has a right of withdrawal, for a period of 14 days
            from the entry into force of the Contract. They may exercise this
            right by sending to QLIP AI, before the expiry of the aforementioned
            period, the form in Appendix 1 hereof, duly completed, or any other
            unambiguous statement expressing their wish to withdraw.
            <br />
            <br />
            In the event that the right of withdrawal is exercised, QLIP AI
            shall refund to the Client all payments received from the Client,
            without undue delay and in any event no later than 14 days from the
            day QLIP AI is informed of the Client's decision to withdraw. The
            refund shall be made using the same means of payment as the one used
            for the initial transaction, unless the Client expressly agrees a
            different means. In any event, this refund will not incur any costs
            for the Client.
            <br />
            <br /> If the Client has requested that the Services start before
            the expiry of the withdrawal period, by ticking the box to this
            effect in their registration form or by accepting the Quote, they
            may exercise their right of withdrawal within the period and in
            accordance with the terms and conditions set out above. In this
            case, the Client shall owe QLIP AI the price of the Subscription,
            calculated on a pro rata basis for the time elapsed until QLIP AI
            was notified of their decision to withdraw.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            14. Agreement of proof
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The Client expressly acknowledges and accepts that the data
            collected on the Platform and QLIP AI 's computer equipment:
            <br />
            <span style={{ marginLeft: "20px", color: "#beff34" }}>
              1. are proof of the reality of the operations carried out in the
              context of the Contract,
            </span>
            <br />
            <span style={{ marginLeft: "20px", color: "#beff34" }}>
              2. constitute the principal method of proof accepted between the
              parties.
            </span>
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            15. Client's obligations and liability
          </Typography>
          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            15.1. Concerning the provision of information
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The Client undertakes to provide QLIP AI with all documents,
            elements, data and information necessary for the performance of the
            Services. More generally, the Client undertakes to cooperate
            actively with QLIP AI and in particular with its dedicated staff for
            the proper execution of the present contract.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            15.2. Concerning the Account
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The client:{" "}
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              guarantees that the information provided in the form is accurate
              and undertakes to keep it up to date,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              acknowledges that this information is proof of their identity and
              is binding as soon as it is validated,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              is responsible for maintaining the confidentiality and security of
              their login and password. Any access to the Platform using their
              username and password is deemed to be made by them.
            </li>
            <br />
            The Client must contact QLIP AI immediately using the contact
            details set out in the "Identification of the company” article if
            they become aware that their Account has been used without their
            knowledge. They acknowledge that QLIP AI shall have the right to
            take any appropriate action in such a case.
            <br />
            <br /> The Client is solely responsible for creating access for
            Users and for setting their access rights. In the event of a breach
            by one of the Users of the obligations set out herein, QLIP AI
            reserves the right to cut off a User's access to their Account
            without delay from the time the said breach is established.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            15.3. Concerning the use of the Services
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The Client is responsible for their use of the Services and any
            information they share in connection therewith. They are also
            responsible for the use of the Services and any information shared
            by Users. They agree that the Services will be used exclusively by
            them and/or Users, which are subject to the same obligations as them
            in their use of the Services.
            <br />
            <br />
            The Client is solely responsible of the Videos and of all content of
            any kind (editorial, graphic, audio, audiovisual or other) included
            in the Videos (the "Content"). The Client guarantees QLIP AI that it
            has all the rights and authorizations necessary for the distribution
            of this Content (including authorization of image rights).
            <br />
            <br />
            The Client is thus forbidden to include in the Videos and to
            broadcast Content, without this list being exhaustive:
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              infringing public order and morality (pornographic, obscene,
              indecent, shocking or unsuitable for a family audience,
              defamatory, insulting, violent, racist, xenophobic or
              revisionist);
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              infringing the rights of third parties (infringing content,
              infringing personality rights, etc.) and, more generally,
              violating a contractual, legislative or regulatory provision;
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              prejudicial to third parties in any way whatsoever;
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              misleading, deceptive or proposing or promoting illegal,
              fraudulent or deceptive activities;
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              harmful to the computer systems of third parties.
            </li>
            <br />
            The Client is solely responsible for their use of the Videos and the
            Final Video.
            <br />
            <br />
            The Client is solely responsible for compliance with the laws and
            regulations applicable to its activity and in particular for
            compliance with any legal notices imposed by them. Consequently, the
            Client may not under any circumstances seek the liability or
            guarantee of QLIP AI in this respect.
            <br />
            <br />
            The Client guarantees QLIP AI that they have all the rights to grant
            it a license to use the Videos and Final Video, allowing it, in
            particular, to produce the Services and to promote them on any media
            and for the legal duration of the author’s rights.
            <br />
            <br />
            The Client will indemnify QLIP AI against any claim and/or action
            that may be brought against QLIP AI as a result of a breach of any
            of their obligations. The Client will indemnify QLIP AI for any loss
            suffered and reimburse it for any sums it may have to bear as a
            result.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            16. Obligations and liability of QLIP AI
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            16.1. QLIP AI undertakes to provide the Services diligently and in
            accordance with the rules of the trade, it being specified that it
            has an obligation of means, to the exclusion of any obligation of
            result, which the Client expressly acknowledges and accepts.
            <br />
            <br />
            16.2. As the Platform is based on non-deterministic machine learning
            algorithms, the Client acknowledges and accepts that QLIP AI cannot
            guarantee that the renderings of the Final Video will meet all its
            needs and expectations, and that QLIP AI cannot be held liable in
            this respect. <br />
            <br />
            16.3. QLIP AI undertakes to use the documents, elements, data,
            information and Content provided to it by the Client only for the
            purposes set out herein. <br />
            <br /> 16.4. QLIP AI guarantees to the Client the full and quiet
            possession of the rights granted on all content on which QLIP AI
            holds rights against all disorders, actions, claims or evictions of
            any kind. <br />
            <br /> 16.5. QLIP AI shall not be held responsible for decisions
            taken by the Client or by any third party appointed by the Client,
            including Users, which contravene the present contract.
            <br />
            <br /> 16.6. QLIP AI undertakes to carry out regular checks to
            verify the operation and accessibility of its Platform. In this
            respect, QLIP AI reserves the right to temporarily interrupt access
            to its Platform for maintenance purposes. However, QLIP AI shall not
            be liable for any temporary difficulties or impossibility of access
            to the Services due to:{" "}
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              circumstances outside its network (and in particular the partial
              or total failure of the Client's servers);
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              the failure of equipment, cabling, services or networks not
              included in the Services or which are not under its
              responsibility;
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              the interruption of the Services due to telecom operators or
              Internet access providers;
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              force majeure.
            </li>
            <br />
            QLIP AI is responsible for the operation of its servers, the
            external limits of which are constituted by the connection points.
            <br />
            <br />
            16.7. QLIP AI does not offer any service level guarantees for the
            Platform. However, the Company makes its best efforts to maintain
            access to the Platform 24 hours a day, 7 days a week, except in the
            event of scheduled maintenance under the conditions defined in the
            "Maintenance" article or in the event of force majeure. <br />
            <br /> 16.8. QLIP AI acts as a hosting provider for the Content that
            the Client puts online. Consequently, it is not responsible for this
            Content. <br />
            <br /> 16.9. If QLIP AI receives a notification concerning an
            illicit Content, it will act promptly to remove it or make it
            impossible to access, and it may take the measures described in the
            article "Sanctions in case of breach". <br />
            <br />
            16.10. QLIP AI shall use its best efforts to save any data entered
            on the Platform. <br />
            <br /> 16.11. In any event, the liability that QLIP AI may incur
            hereunder is expressly limited to the direct damage suffered by the
            Client. If the Client is a professional, the liability shall not
            exceed the amounts received during the 12 months preceding the event
            giving rise to liability or the duration of the provision of its
            Services if this duration is shorter..
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            17. Sanctions in case of breach
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The following are essential obligations towards QLIP AI (the
            "Essential Obligations"):{" "}
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              to pay the price of the Services in accordance with the article
              "Financial Conditions",
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              not to provide false, incomplete or misleading information for the
              subscription and use of the Services,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              not to use the Services for a third party,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              not to engage in illegal, fraudulent activities or activities that
              infringe the rights or safety of third parties, infringe public
              order or violate the laws and regulations in force.
            </li>
            <br />
            In the event of a breach of these Essential Obligations, QLIP AI
            may:{" "}
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              suspend or remove the Client and/or User's access to the Services,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              notify any competent authority, cooperate with it and provide it
              with all information useful for the investigation and suppression
              of illegal or illicit activities,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              take any legal action.
            </li>
            <br />
            These sanctions are without prejudice to any damages that QLIP AI
            may claim from the Client. <br />
            <br />
            In the event of a breach of any obligation other than an Essential
            Obligation, QLIP AI will send a registered letter with
            acknowledgement of receipt to the Client requesting that the breach
            be remedied within a maximum of 15 calendar days. The Services will
            be terminated at the end of this period if the breach is not
            remedied.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            18. Legal guarantee of conformity
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            When the Client is a consumer, they benefit from a legal guarantee
            of conformity in a contract for the supply of digital content or
            services. <br />
            <br />
            When they acts in this sense, the consumer:
            <ul>
              benefits from a time limit which depends on the period of supply
              of the digital content or service by the professional:
              <li style={{ marginLeft: "20px", color: "#beff34" }}>
                when the supply is punctual or is a series of distinct supplies:
                the period to act is 2 years from the supply, or
              </li>
              <li style={{ marginLeft: "20px", color: "#beff34" }}>
                when the supply is continuous: the professional is responsible
                for the conformity that appears during the whole period of
                supply;
              </li>
            </ul>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              may choose, in the event of a lack of conformity, either to bring
              the digital content or service into conformity at no cost, without
              undue delay and without major inconvenience to them, or, failing
              that, to reduce the price or to terminate the contract under the
              conditions of Article L224-25-20 of the French Consumer Code. The
              Client may refuse to comply with the conditions set forth in
              Article L224-25-19 paragraph 1 of the French Consumer Code;
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              in the event of termination of the contract, shall not be required
              to pay for the use they made of the digital content or service
              during the period, prior to termination of the contract, during
              which the content or service was not compliant;
            </li>
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            19. Intellectual Property
          </Typography>
          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            19.1. QLIP AI 's Intellectual Property
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The systems, software, structures, infrastructures, databases and
            content of any kind (texts, images, visuals, music, logos, brands,
            databases, etc.) used by QLIP AI within its Platform are protected
            by all intellectual property rights or database producers' rights in
            force. Any disassembly, decompilation, decryption, extraction,
            reuse, copying and, more generally, any act of reproduction,
            representation, dissemination and use of any of these elements, in
            whole or in part, without the authorization of QLIP AI is strictly
            prohibited and may be subject to legal proceedings. The license that
            QLIP AI grants to the Client does not entail any transfer of
            ownership. <br />
            <br />
            The Client, and the Users, benefit from a non-exclusive and
            non-transferable license in SaaS mode to use the Platform for the
            duration set out in the article "Duration of the Services,
            termination”. <br />
            <br />
            The Client shall refrain from and ensure that Users, as defined
            below, shall refrain from:{" "}
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              reproduce, arrange, adapt all or part of the Platform;
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              proceed with any form of commercial exploitation of the Platform
              with third parties;
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              transferring, providing, lending, renting the Platform, granting
              sub-licences or other rights of use, or more generally,
              communicating all or part of the Platform to a third party or an
              affiliated company.
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              integrate all or part of the Platform into any computer system or
              any other software solution other than those provided for in the
              Contract.
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              to transmit the Platform by remote transmission, to put it on a
              network, in particular on the Internet, outside the Site, or to
              distribute it in any other form, without the prior written
              authorization of QLIP AI.
            </li>
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            19.2. Intellectual property of the Videos
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The Client owns the Videos created on the Platform. The Client is
            therefore free to use them, in particular for commercial purposes.
            <br />
            The Client undertakes not to remove the QLIP AI logo from the Videos
            without QLIP AI's authorization.
            <br />
            <br />
            The Client agrees that their Videos may be broadcast free of charge
            by QLIP AI on the Platform, on its own website and social media
            accounts, as well as in the context of presentations of its
            activity, by any means and on any media, worldwide, or the purposes
            of internal and/or external promotion and communication.
            Furthermore, the Client acknowledges that it may be difficult to
            remove Videos from social media channels and sharing websites (such
            as Facebook, Instagram, YouTube, etc.) and agrees that such Videos
            may remain on social media channels and sharing websites as
            originally published, provided that once this Agreement has come to
            an end, QLIP AI does not republish or authorize others to republish
            such Videos. The Client guarantees QLIP AI in this respect.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            20. Testimonials
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The Client may also make testimonials regarding the Platform. <br />
            Therefore, the Client agrees that QLIP AI may:{" "}
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              broadcast the testimonials free of charge on the Platform and on
              any other French or foreign websites, published by any companies
              with which QLIP AI has agreements, by any means and on any media,
              for the purpose of promoting the Platform,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              translate the testimonials into any language,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              modify (in particular the framing, format and colors) and/or adapt
              the testimonies (in particular to the technical constraints of the
              Platform (alterations or degradations in their quality).
            </li>
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            21. Confidentiality
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            Unless the other party agrees in writing, the parties undertake
            respectively to keep confidential, for the duration of our
            contractual relationship and 3 years thereafter, all information
            relating to or held by the other party of which we become aware on
            the occasion of the conclusion and performance of our contractual
            relationship. <br />
            <br />
            This obligation does not extend to information:
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              of which the receiving party was already aware,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              already public at the time of their communication or which would
              become public without violation of this clause,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              which have been lawfully received from a third party,
            </li>
            <li style={{ marginLeft: "20px", color: "#beff34" }}>
              whose communication would be required by the judicial authorities,
              in application of laws and regulations or in order to establish
              the rights of a party in the context of our contractual
              relationship.
            </li>
            <br />
            Confidential information may be passed on to the respective
            employees, collaborators, trainees, agents and contractors, on
            condition that they are subject to the same obligation of
            confidentiality.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            22. Personal data
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            QLIP AI undertakes to comply with all legal and regulatory
            obligations with regards personal data protection, in particular Law
            78-17 of 6 January 1978 in its latest amended version and the
            Regulation (EU) 2016/679 (General Data Protection Regulation).{" "}
            <br />
            <br /> To learn more on the processing of their personal data and
            their rights, the Client is invited to read the privacy policy of
            QLIP AI.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            23. Audit
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            During the term of this Contract, and provided that the Client is
            not a consumer, QLIP AI may, at its own expense and subject to
            giving the Client at least 30 days' notice by registered letter with
            acknowledgement of receipt, request an inspection at the Client's
            premises or at any other place that enables it to check the
            conditions under which the Platform is used, in particular the
            compliance of its use with the purpose of this licence and, where
            applicable, compliance with the number of authorized Users. <br />
            <br />
            It is expressly agreed between the parties that this audit may be
            carried out by QLIP AI's internal auditors or by an external service
            provider of its choice, without the Client being able to object to
            this. The Client may, however, inform QLIP AI of any reservations
            regarding the choice of auditor, QLIP AI retaining the decision
            whether or not to retain the auditor selected. <br />
            <br />
            The Client shall provide the auditor with access to its premises and
            any relevant documents for the purposes of the audit, during normal
            working hours. QLIP AI undertakes to comply with and to ensure that
            the selected auditor complies with the Client's requirements
            regarding access to its premises and confidentiality. <br />
            <br />
            QLIP AI shall send the Client a copy of the conclusions of its audit
            by any useful written means. The Client shall then have a period of
            30 calendar days, upon receipt of this report, to send QLIP AI any
            observations it may have. <br />
            <br />
            If an audit report reveals non-compliant use of the Platform by the
            Client, the latter undertakes to remedy this, at its own expense and
            as soon as possible, as well as to pay QLIP AI all costs and fees
            incurred by the latter within the framework of the said audit,
            without prejudice to any damages and interest.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            24. Commercial references
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            If the Client is a professional, unless otherwise expressly stated
            in the Quote or instructed to QLIP AI by any useful written means,
            the Client allows QLIP AI to use their name, brand and logo as
            commercial references, on any media and in any form whatsoever, for
            the duration of the present contract and one year thereafter.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            25. Hardship
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            In accordance with the provisions of Article 1218 of the Civil Code,
            no party may be held liable for a failure to perform its contractual
            obligations if such failure is due to an event beyond the control of
            the parties and constituting force majeure. <br />
            <br />
            By force majeure, it is meant the occurrence of an event presenting
            the characteristics of unpredictability, irresistibility and
            exteriority to the parties usually recognized by the law and the
            French courts. This includes strikes, terrorist activities, riots,
            insurrections, wars, governmental actions, natural disasters or
            failure attributable to a third party telecommunication provider.
            <br />
            <br />
            The prevented party shall inform the other party as soon as
            possible, indicating the nature of the case of force majeure. The
            parties shall meet in order to determine together the most
            appropriate means to mitigate, if possible, the consequences of the
            event(s) constituting force majeure. <br />
            <br />
            If the case of force majeure lasts for more than one month, each
            party may terminate the Contract, as of right, without judicial
            formality, without notice and without right to compensation of any
            kind, by any written means with immediate effect. <br />
            <br />
            If, as a result of an event of force majeure, the affected party is
            prevented from fulfilling only part of its contractual obligations,
            it shall remain responsible for the performance of those obligations
            that are not affected by the event of force majeure as well as for
            its payment obligations. <br />
            <br />
            Upon cessation of the force majeure, the affected party shall
            immediately inform the other party and resume performance of the
            affected obligations within a reasonable time.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            26. Subcontracting
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            QLIP AI may use subcontractors to carry out the Services. In this
            case QLIP AI undertakes to ensure that its subcontractors comply
            with the same contractual obligations as those to which it is
            subject under the present contract. QLIP AI remains solely
            responsible for the proper execution of the Services with respect to
            the Client. <br />
            <br />
            QLIP AI may substitute any person who will be subrogated in all its
            rights and obligations under its contractual relationship with the
            Client. Where applicable, it will inform the Client of this
            substitution by any written means.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            27. Mediation
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            If the Client is a consumer, in the event of a dispute, the Client
            can have free recourse to the following consumer ombudsman for an
            amicable settlement: <br />
            <br />
            Centre de médiation de la consommation de conciliateurs de justice
            (CM2C)
            <br />
            Postal address: 14 rue Saint Jean 75017 Paris
            <br />
            Phone : 01 89 47 00 14
            <br />
            <a href="https://www.cm2c.net" style={{ color: "#beff34" }}>
              https://www.cm2c.net
            </a>
            <br />
            <br />
            If the Client is a foreign consumer but located in the European
            Union, he can go to the European Consumer Law Dispute Resolution
            Platform here.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            28. Amendments
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            QLIP AI reserves the right to modify the Contract at any time. The
            Client shall be informed of these modifications by any useful means,
            and in particular by email, at least one month before they come into
            force. <br />
            <br />
            The modified Contract is when the Client's Subscription is renewed.
            <br />
            <br />
            Any Client who does not accept the amended Contract must terminate
            their Subscription, in accordance with the conditions set out in the
            article "Duration of Services, termination". Failing this, the
            Client is deemed to have accepted the changes.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            29. Language
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
          >
            The English language shall prevail in case of contradiction or
            dispute as to the meaning of a term or provision.
          </Typography>

          <Typography
            variant="h3"
            color="#BEFF34"
            fontFamily="PolySans-MedianItalic"
            mb={3}
            mt={3}
          >
            30. Applicable law and jurisdiction
          </Typography>
          <Typography
            variant="body1"
            style={{ color: "white" }}
            lineHeight="30px"
            mb={5}
          >
            The Contract is subject to French law and shall be governed and
            interpreted in accordance with that law. <br />
            <br />
            If the Client is a professional, any dispute that may arise in
            connection with their validity, interpretation or performance shall
            be subject to the exclusive jurisdiction of the courts of Paris
            (France), unless otherwise provided by mandatory procedural rules.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
