// @mui/material
import { Box, Grid, Typography } from "@mui/material";
// @data
import { backedBy } from "../data/backedBy";

export default function BackedBy(): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight="600px"
    >
      <Typography
        variant="h2"
        align="center"
        color="white"
        gutterBottom
        mb={10}
      >
        Backed by
      </Typography>

      <Grid container pb={10}>
        {backedBy.map((back) => (
          <Grid item xs={12} sm={6} md={6} lg={3} key={back.name}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              mb={5}
            >
              <img src={back.src} alt={back.name} width="300px" />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
